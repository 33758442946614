// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as CoursesCurriculum__User from "./CoursesCurriculum__User.bs.js";
import * as CoursesCurriculum__Reaction from "./CoursesCurriculum__Reaction.bs.js";
import * as CoursesCurriculum__ModerationReport from "./CoursesCurriculum__ModerationReport.bs.js";

function id(t) {
  return t.id;
}

function userId(t) {
  return t.userId;
}

function user(t) {
  return t.user;
}

function submissionId(t) {
  return t.submissionId;
}

function comment(t) {
  return t.comment;
}

function reactions(t) {
  return t.reactions;
}

function moderationReports(t) {
  return t.moderationReports;
}

function createdAt(t) {
  return t.createdAt;
}

function hiddenAt(t) {
  return t.hiddenAt;
}

function hiddenById(t) {
  return t.hiddenById;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function hiddenAtPretty(t) {
  var hiddenAt = t.hiddenAt;
  if (hiddenAt !== undefined) {
    return DateFns.format(Caml_option.valFromOption(hiddenAt), "MMMM d, yyyy");
  } else {
    return "";
  }
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          userId: Json_decode.field("userId", Json_decode.string, json),
          user: Json_decode.field("user", CoursesCurriculum__User.decode, json),
          submissionId: Json_decode.field("submissionId", Json_decode.string, json),
          comment: Json_decode.field("comment", Json_decode.string, json),
          reactions: Json_decode.field("reactions", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Reaction.decode, param);
                }), json),
          moderationReports: Json_decode.field("moderationReports", (function (param) {
                  return Json_decode.array(CoursesCurriculum__ModerationReport.decode, param);
                }), json),
          createdAt: Json_decode.field("createdAt", DateFns.decodeISO, json),
          hiddenAt: Json_decode.optional((function (param) {
                  return Json_decode.field("hiddenAt", DateFns.decodeISO, param);
                }), json),
          hiddenById: Json_decode.optional((function (param) {
                  return Json_decode.field("hiddenById", Json_decode.string, param);
                }), json)
        };
}

export {
  id ,
  userId ,
  user ,
  submissionId ,
  comment ,
  reactions ,
  moderationReports ,
  createdAt ,
  hiddenAt ,
  hiddenById ,
  createdAtPretty ,
  hiddenAtPretty ,
  decode ,
}
/* DateFns Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as CommunitiesShow__TopicParticipant from "./CommunitiesShow__TopicParticipant.bs.js";

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function solved(t) {
  return t.solved;
}

function views(t) {
  return t.views;
}

function lastActivityAt(t) {
  return t.lastActivityAt;
}

function liveRepliesCount(t) {
  return t.liveRepliesCount;
}

function likesCount(t) {
  return t.likesCount;
}

function topicCategoryId(t) {
  return t.topicCategoryId;
}

function creatorName(t) {
  return t.creatorName;
}

function createdAt(t) {
  return t.createdAt;
}

function participants(t) {
  return t.participants;
}

function participantsCount(t) {
  return t.participantsCount;
}

function make(id, title, lastActivityAt, liveRepliesCount, likesCount, views, topicCategoryId, creatorName, createdAt, solved, participantsCount, participants) {
  return {
          id: id,
          title: title,
          lastActivityAt: lastActivityAt,
          liveRepliesCount: liveRepliesCount,
          likesCount: likesCount,
          views: views,
          topicCategoryId: topicCategoryId,
          creatorName: creatorName,
          createdAt: createdAt,
          solved: solved,
          participantsCount: participantsCount,
          participants: participants
        };
}

function makeFromJS(topicData) {
  return make(topicData.id, topicData.title, Belt_Option.map(topicData.lastActivityAt, DateFns.decodeISO), topicData.liveRepliesCount, topicData.likesCount, topicData.views, topicData.topicCategoryId, Belt_Option.map(topicData.creator, (function (creator) {
                    return creator.name;
                  })), DateFns.decodeISO(topicData.createdAt), topicData.solved, topicData.participantsCount, Js_array.map(CommunitiesShow__TopicParticipant.makeFromJs, topicData.participants));
}

export {
  id ,
  title ,
  solved ,
  views ,
  lastActivityAt ,
  liveRepliesCount ,
  likesCount ,
  topicCategoryId ,
  creatorName ,
  createdAt ,
  participants ,
  participantsCount ,
  make ,
  makeFromJS ,
}
/* DateFns Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function title(t) {
  return t.title;
}

function lockedAt(t) {
  return t.lockedAt;
}

function lockedById(t) {
  return t.lockedById;
}

function id(t) {
  return t.id;
}

function topicCategoryId(t) {
  return t.topicCategoryId;
}

function updateTitle(title, t) {
  return {
          id: t.id,
          title: title,
          topicCategoryId: t.topicCategoryId,
          lockedAt: t.lockedAt,
          lockedById: t.lockedById
        };
}

function lock(lockedById, t) {
  return {
          id: t.id,
          title: t.title,
          topicCategoryId: t.topicCategoryId,
          lockedAt: Caml_option.some(new Date()),
          lockedById: lockedById
        };
}

function unlock(t) {
  return {
          id: t.id,
          title: t.title,
          topicCategoryId: t.topicCategoryId,
          lockedAt: undefined,
          lockedById: undefined
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          topicCategoryId: Json_decode.optional((function (param) {
                  return Json_decode.field("topicCategoryId", Json_decode.string, param);
                }), json),
          lockedAt: Json_decode.optional((function (param) {
                  return Json_decode.field("lockedAt", DateFns.decodeISO, param);
                }), json),
          lockedById: Json_decode.optional((function (param) {
                  return Json_decode.field("lockedById", Json_decode.string, param);
                }), json)
        };
}

export {
  title ,
  lockedAt ,
  lockedById ,
  id ,
  topicCategoryId ,
  updateTitle ,
  lock ,
  unlock ,
  decode ,
}
/* DateFns Not a pure module */

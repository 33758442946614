// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as AppRouter__User from "./types/AppRouter__User.bs.js";
import * as Caml_exceptions from "../../../../../../shared/node_modules/rescript/lib/es6/caml_exceptions.js";
import * as CoursesReview__Root from "../../courses/review/CoursesReview__Root.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesReview__SubmissionsRoot from "../../courses/review/components/CoursesReview__SubmissionsRoot.bs.js";
import * as CoursesStudents__StudentOverlay from "../../courses/students/components/CoursesStudents__StudentOverlay.bs.js";

var UnknownPathEncountered = /* @__PURE__ */Caml_exceptions.create("AppRouter.UnknownPathEncountered");

import "./components/AppRouter__Nav.css"
;

function AppRouter(Props) {
  var courses = Props.courses;
  var currentUser = Props.currentUser;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var component;
  var exit = 0;
  if (match) {
    switch (match.hd) {
      case "courses" :
          var match$1 = match.tl;
          if (match$1) {
            var match$2 = match$1.tl;
            if (match$2 && match$2.hd === "review" && !match$2.tl) {
              component = React.createElement(CoursesReview__Root.make, {
                    courseId: match$1.hd,
                    currentCoachId: Belt_Option.getWithDefault(AppRouter__User.coachId(AppRouter__User.defaultUser(currentUser)), ""),
                    courses: courses
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "students" :
          var match$3 = match.tl;
          if (match$3) {
            var match$4 = match$3.tl;
            if (match$4 && match$4.hd === "report" && !match$4.tl) {
              component = React.createElement(CoursesStudents__StudentOverlay.make, {
                    studentId: match$3.hd,
                    userId: AppRouter__User.id(AppRouter__User.defaultUser(currentUser))
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "submissions" :
          var match$5 = match.tl;
          if (match$5) {
            var match$6 = match$5.tl;
            if (match$6 && match$6.hd === "review" && !match$6.tl) {
              component = React.createElement(CoursesReview__SubmissionsRoot.make, {
                    submissionId: match$5.hd,
                    currentUser: AppRouter__User.defaultUser(currentUser)
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    Rollbar.critical("Unknown path encountered by app router: " + Js_array.joinWith("/", $$Array.of_list(url.path)));
    throw {
          RE_EXN_ID: UnknownPathEncountered,
          _1: url.path,
          Error: new Error()
        };
  }
  return React.createElement("div", {
              className: "md:h-screen md:flex bg-gray-50"
            }, component);
}

var make = AppRouter;

export {
  UnknownPathEncountered ,
  make ,
}
/*  Not a pure module */

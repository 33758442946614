// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as StringUtils from "../../../shared/utils/StringUtils.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import LevelLockSvg from "../images/level-lock.svg";
import * as CoursesCurriculum__Level from "../types/CoursesCurriculum__Level.bs.js";
import LevelEmptySvg from "../images/level-empty.svg";
import * as CoursesCurriculum__Course from "../types/CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Overlay from "./CoursesCurriculum__Overlay.bs.js";
import * as CoursesCurriculum__Student from "../types/CoursesCurriculum__Student.bs.js";
import * as CoursesCurriculum__TargetGroup from "../types/CoursesCurriculum__TargetGroup.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__LevelSelector from "./CoursesCurriculum__LevelSelector.bs.js";
import * as CoursesCurriculum__NoticeManager from "./CoursesCurriculum__NoticeManager.bs.js";
import * as CoursesCurriculum__LatestSubmission from "../types/CoursesCurriculum__LatestSubmission.bs.js";

import "./CoursesCurriculum.css"
;

var levelLockedImage = LevelLockSvg;

var levelEmptyImage = LevelEmptySvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function targetStatusClasses(targetStatus) {
  var statusClasses = "curriculum__target-status--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
  return "curriculum__target-status px-1 md:px-3 py-px ms-4 h-6 " + statusClasses;
}

function rendertarget(target, statusOfTargets, targetsRead, author, courseId) {
  var targetId = CoursesCurriculum__Target.id(target);
  var targetStatus = ArrayUtils.unsafeFind((function (ts) {
          return CoursesCurriculum__TargetStatus.targetId(ts) === targetId;
        }), "Could not find targetStatus for listed target with ID " + targetId, statusOfTargets);
  var targetRead = Js_array.includes(targetId, targetsRead);
  return React.createElement("div", {
              key: "target-" + targetId,
              className: "courses-curriculum__target-container flex border-t bg-white hover:bg-gray-50"
            }, React.createElement(Link.make, {
                  href: "/targets/" + targetId,
                  ariaLabel: "Select Target: " + (CoursesCurriculum__Target.title(target) + ", Status: " + CoursesCurriculum__TargetStatus.statusToString(targetStatus)),
                  className: "p-3 md:p-6 flex flex-1 items-start justify-between hover:text-primary-500 cursor-pointer focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 focus:text-primary-500 focus:bg-gray-50 focus:rounded-lg",
                  children: null,
                  props: {
                    "data-target-id": targetId
                  }
                }, React.createElement("span", {
                      className: "inline-flex items-center space-x-3"
                    }, targetRead ? React.createElement("span", {
                            className: "w-5 h-5 flex items-center justify-center",
                            title: "Marked read"
                          }, React.createElement("svg", {
                                className: "w-4 h-4 text-gray-500",
                                height: "16",
                                width: "16",
                                fill: "currentColor",
                                viewBox: "0 0 16 16",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, React.createElement("path", {
                                    d: "M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"
                                  }), React.createElement("path", {
                                    d: "m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"
                                  }))) : React.createElement("span", {
                            className: "w-5 h-5 flex items-center justify-center",
                            title: "Not read yet"
                          }, React.createElement("span", {
                                className: "w-2 h-2 inline-block rounded-full bg-blue-600"
                              })), React.createElement("span", {
                          className: "text-sm md:text-base font-medium"
                        }, CoursesCurriculum__Target.title(target))), React.createElement("div", {
                      className: "flex"
                    }, CoursesCurriculum__Target.milestone(target) ? React.createElement("div", {
                            className: "flex items-center flex-shrink-0 text-xs font-medium border border-yellow-200 bg-yellow-100 text-yellow-800 ms-3 px-1.5 md:px-2 py-1 rounded-md"
                          }, React.createElement(Icon.make, {
                                className: "if i-milestone-solid text-sm"
                              }), React.createElement("span", {
                                className: "hidden md:block ms-1"
                              }, t(undefined, undefined, "milestone_target_label"))) : null, ReactUtils.nullIf(React.createElement("span", {
                              className: targetStatusClasses(targetStatus)
                            }, CoursesCurriculum__TargetStatus.statusToString(targetStatus)), CoursesCurriculum__TargetStatus.isAccessEnded(targetStatus) || CoursesCurriculum__TargetStatus.isPending(targetStatus)))), ReactUtils.nullUnless(React.createElement("a", {
                      "aria-label": t([[
                              "title",
                              CoursesCurriculum__Target.title(target)
                            ]], undefined, "edit_target_button_title"),
                      className: "hidden lg:block courses-curriculum__target-quick-link text-gray-400 border-s border-transparent py-6 px-3 hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 focus:bg-gray-50 focus:text-primary-500 focus:rounded-lg",
                      title: t([[
                              "title",
                              CoursesCurriculum__Target.title(target)
                            ]], undefined, "edit_target_button_title"),
                      href: "/school/courses/" + courseId + "/targets/" + targetId + "/content"
                    }, React.createElement("i", {
                          className: "fas fa-pencil-alt"
                        })), author));
}

function renderTargetGroup(targetGroup, targets, statusOfTargets, targetsRead, author, courseId) {
  var targetGroupId = CoursesCurriculum__TargetGroup.id(targetGroup);
  var targets$1 = Js_array.filter((function (t) {
          return CoursesCurriculum__Target.targetGroupId(t) === targetGroupId;
        }), targets);
  return React.createElement("div", {
              key: "target-group-" + targetGroupId,
              className: "curriculum__target-group-container relative mt-5 px-3"
            }, React.createElement("div", {
                  className: "curriculum__target-group max-w-3xl md:max-w-4xl 2xl:max-w-5xl mx-auto bg-white rounded-lg shadow-md relative overflow-hidden "
                }, React.createElement("div", {
                      className: "p-3 md:p-6 pt-5 text-center"
                    }, React.createElement("div", {
                          className: "text-2xl font-bold leading-snug"
                        }, CoursesCurriculum__TargetGroup.name(targetGroup)), React.createElement(MarkdownBlock.make, {
                          markdown: CoursesCurriculum__TargetGroup.description(targetGroup),
                          className: "text-sm max-w-md mx-auto leading-snug",
                          profile: /* AreaOfText */1
                        })), Js_array.map((function (target) {
                        return rendertarget(target, statusOfTargets, targetsRead, author, courseId);
                      }), ArrayUtils.copyAndSort((function (t1, t2) {
                            return CoursesCurriculum__Target.sortIndex(t1) - CoursesCurriculum__Target.sortIndex(t2) | 0;
                          }), targets$1))));
}

function addSubmission(setState, latestSubmission) {
  return Curry._1(setState, (function (state) {
                var withoutSubmissionForThisTarget = Js_array.filter((function (s) {
                        return CoursesCurriculum__LatestSubmission.targetId(s) !== CoursesCurriculum__LatestSubmission.targetId(latestSubmission);
                      }), state.latestSubmissions);
                return {
                        selectedLevelId: state.selectedLevelId,
                        showLevelZero: state.showLevelZero,
                        latestSubmissions: Js_array.concat([latestSubmission], withoutSubmissionForThisTarget),
                        statusOfTargets: state.statusOfTargets,
                        targetsRead: state.targetsRead,
                        notice: state.notice
                      };
              }));
}

function addMarkRead(setState, markedReadTargetId) {
  return Curry._1(setState, (function (state) {
                return {
                        selectedLevelId: state.selectedLevelId,
                        showLevelZero: state.showLevelZero,
                        latestSubmissions: state.latestSubmissions,
                        statusOfTargets: state.statusOfTargets,
                        targetsRead: Js_array.concat([markedReadTargetId], state.targetsRead),
                        notice: state.notice
                      };
              }));
}

function handleLockedLevel(level) {
  var date = CoursesCurriculum__Level.unlockAt(level);
  var tmp;
  if (date !== undefined) {
    var dateString = DateFns.format(Caml_option.valFromOption(date), "MMMM d, yyyy");
    tmp = React.createElement("div", {
          className: "font-semibold text-md px-3"
        }, React.createElement("p", undefined, t(undefined, undefined, "level_locked_notice")), React.createElement("p", undefined, t([[
                    "date",
                    dateString
                  ]], undefined, "level_locked_explanation")));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "max-w-xl mx-auto text-center mt-4"
            }, React.createElement("div", {
                  className: "text-2xl font-bold px-3"
                }, t(undefined, undefined, "level_locked")), React.createElement("img", {
                  className: "max-w-sm mx-auto",
                  src: levelLockedImage
                }), tmp);
}

function issuedCertificate(course) {
  var csn = CoursesCurriculum__Course.certificateSerialNumber(course);
  if (csn !== undefined) {
    return React.createElement("div", {
                className: "max-w-3xl md:max-w-4xl 2xl:max-w-5xl mx-auto text-center mt-4 bg-white lg:rounded-lg shadow-md px-6 pt-6 pb-8"
              }, React.createElement("div", {
                    className: "font-semibold text-xl mx-auto mt-2 leading-tight"
                  }, t(undefined, undefined, "issued_certificate_heading")), React.createElement("a", {
                    className: "mt-4 mb-2 btn btn-primary",
                    href: "/c/" + csn
                  }, React.createElement(FaIcon.make, {
                        classes: "fas fa-certificate"
                      }), React.createElement("span", {
                        className: "ms-2"
                      }, t(undefined, undefined, "issued_certificate_button"))));
  } else {
    return null;
  }
}

function computeNotice(course, student, preview) {
  if (preview) {
    return /* Preview */0;
  } else if (CoursesCurriculum__Course.ended(course)) {
    return /* CourseEnded */1;
  } else if (CoursesCurriculum__Student.accessEnded(student)) {
    return /* AccessEnded */3;
  } else if (Belt_Option.isSome(CoursesCurriculum__Student.completedAt(student))) {
    return /* CourseComplete */2;
  } else {
    return /* Nothing */4;
  }
}

function navigationLink(direction, level, setState) {
  var match = direction === "Next" ? [
      undefined,
      t(undefined, undefined, "nav_aria_next_level"),
      t(undefined, undefined, "nav_long_next_level"),
      t(undefined, undefined, "nav_short_next_level"),
      "fa-arrow-right rtl:rotate-180"
    ] : [
      "fa-arrow-left rtl:rotate-180",
      t(undefined, undefined, "nav_aria_previous_level"),
      t(undefined, undefined, "nav_long_previous_level"),
      t(undefined, undefined, "nav_short_previous_level"),
      undefined
    ];
  var arrow = function (icon) {
    return Belt_Option.mapWithDefault(icon, null, (function (icon) {
                  return React.createElement(FaIcon.make, {
                              classes: "fas " + icon
                            });
                }));
  };
  return React.createElement("button", {
              "aria-label": match[1],
              className: "block w-full focus:outline-none p-4 text-center border rounded-lg bg-gray-100 hover:bg-primary-50 cursor-pointer hover:text-primary-500 focus:text-primary-500 focus:bg-gray-50 focus:ring-2 focus:ring-inset focus:ring-focusColor-500",
              onClick: (function (param) {
                  Curry._1(setState, (function (state) {
                          return {
                                  selectedLevelId: CoursesCurriculum__Level.id(level),
                                  showLevelZero: state.showLevelZero,
                                  latestSubmissions: state.latestSubmissions,
                                  statusOfTargets: state.statusOfTargets,
                                  targetsRead: state.targetsRead,
                                  notice: state.notice
                                };
                        }));
                })
            }, arrow(match[0]), React.createElement("span", {
                  className: "mx-2 hidden md:inline"
                }, match[2]), React.createElement("span", {
                  className: "mx-2 inline md:hidden"
                }, match[3]), arrow(match[4]));
}

function quickNavigationLinks(levels, selectedLevel, setState) {
  var previous = CoursesCurriculum__Level.previous(levels, selectedLevel);
  var next = CoursesCurriculum__Level.next(levels, selectedLevel);
  return React.createElement("div", {
              key: "quick-navigation-links"
            }, React.createElement("hr", {
                  className: "my-6"
                }), React.createElement("div", {
                  className: "container mx-auto max-w-3xl flex px-3 lg:px-0"
                }, previous !== undefined ? (
                    next !== undefined ? [
                        React.createElement("div", {
                              key: "previous",
                              className: "w-1/2 me-2"
                            }, navigationLink("Previous", previous, setState)),
                        React.createElement("div", {
                              key: "next",
                              className: "w-1/2 ms-2"
                            }, navigationLink("Next", next, setState))
                      ] : React.createElement("div", {
                            className: "w-full"
                          }, navigationLink("Previous", previous, setState))
                  ) : (
                    next !== undefined ? React.createElement("div", {
                            className: "w-full"
                          }, navigationLink("Next", next, setState)) : null
                  )));
}

function CoursesCurriculum(Props) {
  var currentUser = Props.currentUser;
  var author = Props.author;
  var course = Props.course;
  var levels = Props.levels;
  var targetGroups = Props.targetGroups;
  var targets = Props.targets;
  var submissions = Props.submissions;
  var student = Props.student;
  var coaches = Props.coaches;
  var users = Props.users;
  var evaluationCriteria = Props.evaluationCriteria;
  var preview = Props.preview;
  var accessLockedLevels = Props.accessLockedLevels;
  var targetsRead = Props.targetsRead;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var selectedTarget;
  if (match && match.hd === "targets") {
    var match$1 = match.tl;
    selectedTarget = match$1 ? Belt_Option.map(StringUtils.paramToId(match$1.hd), (function (targetId) {
              return ArrayUtils.unsafeFind((function (t) {
                            return CoursesCurriculum__Target.id(t) === targetId;
                          }), "Could not find selectedTarget with ID " + targetId, targets);
            })) : undefined;
  } else {
    selectedTarget = undefined;
  }
  var levelZero = Js_array.find((function (l) {
          return CoursesCurriculum__Level.number(l) === 0;
        }), levels);
  var studentLevelId = CoursesCurriculum__Student.levelId(student);
  var targetLevelId;
  if (selectedTarget !== undefined) {
    var targetGroupId = CoursesCurriculum__Target.targetGroupId(selectedTarget);
    var targetGroup = ArrayUtils.unsafeFind((function (t) {
            return CoursesCurriculum__TargetGroup.id(t) === targetGroupId;
          }), "Could not find targetGroup with ID " + targetGroupId, targetGroups);
    targetLevelId = CoursesCurriculum__TargetGroup.levelId(targetGroup);
  } else {
    targetLevelId = undefined;
  }
  var computeTargetStatus = function (param, param$1) {
    return CoursesCurriculum__TargetStatus.compute(preview, student, course, levels, targetGroups, targets, param, param$1);
  };
  var initialRender = React.useRef(true);
  var match$2 = React.useState(function () {
        var statusOfTargets = computeTargetStatus(targetsRead, submissions);
        var tmp;
        var exit = 0;
        if (preview && targetLevelId === undefined) {
          tmp = CoursesCurriculum__Level.id(CoursesCurriculum__Level.first(levels));
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = targetLevelId !== undefined && !(levelZero !== undefined && CoursesCurriculum__Level.id(levelZero) === targetLevelId) ? targetLevelId : studentLevelId;
        }
        return {
                selectedLevelId: tmp,
                showLevelZero: levelZero !== undefined && targetLevelId !== undefined ? CoursesCurriculum__Level.id(levelZero) === targetLevelId : false,
                latestSubmissions: submissions,
                statusOfTargets: statusOfTargets,
                targetsRead: targetsRead,
                notice: computeNotice(course, student, preview)
              };
      });
  var setState = match$2[1];
  var state = match$2[0];
  var match$3 = state.showLevelZero;
  var currentLevelId = levelZero !== undefined && match$3 ? CoursesCurriculum__Level.id(levelZero) : state.selectedLevelId;
  var currentLevel = ArrayUtils.unsafeFind((function (l) {
          return CoursesCurriculum__Level.id(l) === currentLevelId;
        }), "Could not find currentLevel with id " + currentLevelId, levels);
  var selectedLevel = ArrayUtils.unsafeFind((function (l) {
          return CoursesCurriculum__Level.id(l) === state.selectedLevelId;
        }), "Could not find selectedLevel with id " + state.selectedLevelId, levels);
  React.useEffect((function () {
          if (initialRender.current) {
            initialRender.current = false;
          } else {
            var newStatusOfTargets = computeTargetStatus(state.targetsRead, state.latestSubmissions);
            Curry._1(setState, (function (state) {
                    return {
                            selectedLevelId: state.selectedLevelId,
                            showLevelZero: state.showLevelZero,
                            latestSubmissions: state.latestSubmissions,
                            statusOfTargets: newStatusOfTargets,
                            targetsRead: state.targetsRead,
                            notice: computeNotice(course, student, preview)
                          };
                  }));
          }
        }), [
        state.latestSubmissions,
        state.targetsRead
      ]);
  var targetGroupsInLevel = Js_array.filter((function (tg) {
          return CoursesCurriculum__TargetGroup.levelId(tg) === currentLevelId;
        }), targetGroups);
  var tmp;
  if (selectedTarget !== undefined) {
    var targetStatus = ArrayUtils.unsafeFind((function (ts) {
            return CoursesCurriculum__TargetStatus.targetId(ts) === CoursesCurriculum__Target.id(selectedTarget);
          }), "Could not find targetStatus for selectedTarget with ID " + CoursesCurriculum__Target.id(selectedTarget), state.statusOfTargets);
    var targetRead = Js_array.includes(CoursesCurriculum__Target.id(selectedTarget), state.targetsRead);
    tmp = React.createElement(CoursesCurriculum__Overlay.make, {
          target: selectedTarget,
          course: course,
          targetStatus: targetStatus,
          addSubmissionCB: (function (param) {
              return addSubmission(setState, param);
            }),
          targets: targets,
          targetRead: targetRead,
          markReadCB: (function (param) {
              return addMarkRead(setState, param);
            }),
          statusOfTargets: state.statusOfTargets,
          users: users,
          evaluationCriteria: evaluationCriteria,
          coaches: coaches,
          preview: preview,
          author: author,
          currentUser: currentUser
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              "aria-label": "Curriculum",
              className: "md:h-screen bg-gray-50 md:pt-18 pb-20 md:pb-8 overflow-y-auto",
              role: "main"
            }, tmp, issuedCertificate(course), React.createElement(CoursesCurriculum__NoticeManager.make, {
                  notice: state.notice
                }), [
              React.createElement("div", {
                    key: "curriculum-body",
                    className: "relative"
                  }, React.createElement(CoursesCurriculum__LevelSelector.make, {
                        levels: levels,
                        selectedLevel: selectedLevel,
                        preview: preview,
                        setSelectedLevelId: (function (selectedLevelId) {
                            Curry._1(setState, (function (state) {
                                    return {
                                            selectedLevelId: selectedLevelId,
                                            showLevelZero: state.showLevelZero,
                                            latestSubmissions: state.latestSubmissions,
                                            statusOfTargets: state.statusOfTargets,
                                            targetsRead: state.targetsRead,
                                            notice: state.notice
                                          };
                                  }));
                          }),
                        showLevelZero: state.showLevelZero,
                        setShowLevelZero: (function (showLevelZero) {
                            Curry._1(setState, (function (state) {
                                    return {
                                            selectedLevelId: state.selectedLevelId,
                                            showLevelZero: showLevelZero,
                                            latestSubmissions: state.latestSubmissions,
                                            statusOfTargets: state.statusOfTargets,
                                            targetsRead: state.targetsRead,
                                            notice: state.notice
                                          };
                                  }));
                          }),
                        levelZero: levelZero
                      }), ReactUtils.nullUnless(React.createElement("div", {
                            className: "text-center mt-2 max-w-3xl mx-auto"
                          }, React.createElement("a", {
                                className: "btn btn-primary-ghost btn-small",
                                href: "/school/courses/" + CoursesCurriculum__Course.id(course) + "/curriculum?level=" + String(CoursesCurriculum__Level.number(currentLevel))
                              }, React.createElement("i", {
                                    className: "fas fa-pencil-alt"
                                  }), React.createElement("span", {
                                    className: "ms-2"
                                  }, t(undefined, undefined, "edit_level_button")))), author), CoursesCurriculum__Level.isLocked(currentLevel) && accessLockedLevels ? React.createElement("div", {
                          className: "text-center p-3 mt-5 border rounded-lg bg-blue-100 max-w-3xl mx-auto",
                          dangerouslySetInnerHTML: {
                            __html: t([[
                                    "date",
                                    CoursesCurriculum__Level.unlockDateString(currentLevel)
                                  ]], undefined, "level_locked_for_students_notice")
                          }
                        }) : null, CoursesCurriculum__Level.isUnlocked(currentLevel) || accessLockedLevels ? (
                      Caml_obj.equal(targetGroupsInLevel, []) ? React.createElement("div", {
                              className: "mx-auto py-10"
                            }, React.createElement("img", {
                                  className: "max-w-xs md:max-w-sm mx-auto",
                                  src: levelEmptyImage
                                }), React.createElement("p", {
                                  className: "text-center font-semibold text-lg mt-4"
                                }, t(undefined, undefined, "empty_level_content_notice"))) : Js_array.map((function (targetGroup) {
                                return renderTargetGroup(targetGroup, targets, state.statusOfTargets, state.targetsRead, author, CoursesCurriculum__Course.id(course));
                              }), CoursesCurriculum__TargetGroup.sort(targetGroupsInLevel))
                    ) : handleLockedLevel(currentLevel)),
              state.showLevelZero ? null : quickNavigationLinks(levels, selectedLevel, setState)
            ]);
}

var make = CoursesCurriculum;

export {
  levelLockedImage ,
  levelEmptyImage ,
  str ,
  t ,
  targetStatusClasses ,
  rendertarget ,
  renderTargetGroup ,
  addSubmission ,
  addMarkRead ,
  handleLockedLevel ,
  issuedCertificate ,
  computeNotice ,
  navigationLink ,
  quickNavigationLinks ,
  make ,
}
/*  Not a pure module */

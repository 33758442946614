// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as User from "../../../shared/types/User.bs.js";
import * as $$Array from "../../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as Cohort from "../../../shared/types/Cohort.bs.js";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as ScrollLock from "../../../shared/utils/ScrollLock.bs.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as ClickToCopy from "../../../shared/components/ClickToCopy.bs.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";
import * as UserDetails from "../../../shared/types/UserDetails.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesStudents__CoachNote from "../types/CoursesStudents__CoachNote.bs.js";
import * as CoursesStudents__CoachNotes from "./CoursesStudents__CoachNotes.bs.js";
import * as CoursesStudents__StudentInfo from "../types/CoursesStudents__StudentInfo.bs.js";
import * as CoursesStudents__StudentDetails from "../types/CoursesStudents__StudentDetails.bs.js";
import * as CoursesStudents__PersonalCoaches from "./CoursesStudents__PersonalCoaches.bs.js";
import * as CoursesStudents__SubmissionsList from "./CoursesStudents__SubmissionsList.bs.js";
import * as CoursesStudents__EvaluationCriterion from "../types/CoursesStudents__EvaluationCriterion.bs.js";
import * as CoursesStudents__MilestoneTargetsCompletionStatus from "../types/CoursesStudents__MilestoneTargetsCompletionStatus.bs.js";

import "./CoursesStudents__StudentOverlay.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__StudentOverlay";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var initialState = {
  selectedTab: /* Notes */0,
  studentData: /* Loading */0,
  submissions: /* Unloaded */0
};

function closeOverlayLink(student) {
  var search = window.location.search;
  var cohortId = Cohort.id(CoursesStudents__StudentInfo.cohort(student));
  return "/cohorts/" + (cohortId + "/students") + search;
}

var Raw = {};

var query = "query StudentDetailsQuery($studentId: ID!)  {\nstudentDetails(studentId: $studentId)  {\nemail  \nevaluationCriteria  {\nid  \nname  \nmaxGrade  \n}\n\nstudent  {\nid  \ntaggings  \nuser  {\n...UserDetailsFragment   \n}\n\ncohort  {\n...CohortFragment   \n}\n\npersonalCoaches  {\n...UserProxyFragment   \n}\n\ndroppedOutAt  \ncourse  {\nid  \n}\n\n}\n\ntotalTargets  \ntargetsCompleted  \nquizScores  \naverageGrades  {\nevaluationCriterionId  \naverageGrade  \n}\n\nmilestoneTargetsCompletionStatus  {\nid  \ntitle  \nmilestoneNumber  \ncompleted  \n}\n\ncanModifyCoachNotes  \nteam  {\nid  \nname  \nstudents  {\nid  \ntaggings  \nuser  {\n...UserDetailsFragment   \n}\n\ncohort  {\n...CohortFragment   \n}\n\npersonalCoaches  {\n...UserProxyFragment   \n}\n\ndroppedOutAt  \n}\n\n}\n\n}\n\ncoachNotes(studentId: $studentId)  {\nid  \nnote  \ncreatedAt  \nauthor  {\n...UserFragment   \n}\n\n}\n\nhasArchivedCoachNotes(studentId: $studentId)  \n}\n" + Cohort.Fragment.query + UserDetails.Fragment.query + User.Fragment.query + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.studentDetails;
  var value$2 = value$1.evaluationCriteria;
  var value$3 = value$1.student;
  var value$4 = value$3.taggings;
  var value$5 = value$3.user;
  var value$6 = value$3.cohort;
  var value$7 = value$3.personalCoaches;
  var value$8 = value$3.droppedOutAt;
  var value$9 = value$3.course;
  var value$10 = value$1.quizScores;
  var value$11 = value$1.averageGrades;
  var value$12 = value$1.milestoneTargetsCompletionStatus;
  var value$13 = value$1.team;
  var tmp;
  if (value$13 == null) {
    tmp = undefined;
  } else {
    var value$14 = value$13.students;
    tmp = {
      id: value$13.id,
      name: value$13.name,
      students: Js_array.map((function (value) {
              var value$1 = value.taggings;
              var value$2 = value.user;
              var value$3 = value.cohort;
              var value$4 = value.personalCoaches;
              var value$5 = value.droppedOutAt;
              return {
                      id: value.id,
                      taggings: Js_array.map((function (value) {
                              return value;
                            }), value$1),
                      user: UserDetails.Fragment.verifyArgsAndParse("UserDetailsFragment", value$2),
                      cohort: Cohort.Fragment.verifyArgsAndParse("CohortFragment", value$3),
                      personalCoaches: Js_array.map((function (value) {
                              return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
                            }), value$4),
                      droppedOutAt: !(value$5 == null) ? Caml_option.some(value$5) : undefined
                    };
            }), value$14)
    };
  }
  var value$15 = value.coachNotes;
  return {
          studentDetails: {
            email: value$1.email,
            evaluationCriteria: Js_array.map((function (value) {
                    return {
                            id: value.id,
                            name: value.name,
                            maxGrade: value.maxGrade
                          };
                  }), value$2),
            student: {
              id: value$3.id,
              taggings: Js_array.map((function (value) {
                      return value;
                    }), value$4),
              user: UserDetails.Fragment.verifyArgsAndParse("UserDetailsFragment", value$5),
              cohort: Cohort.Fragment.verifyArgsAndParse("CohortFragment", value$6),
              personalCoaches: Js_array.map((function (value) {
                      return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
                    }), value$7),
              droppedOutAt: !(value$8 == null) ? Caml_option.some(value$8) : undefined,
              course: {
                id: value$9.id
              }
            },
            totalTargets: value$1.totalTargets,
            targetsCompleted: value$1.targetsCompleted,
            quizScores: Js_array.map((function (value) {
                    return value;
                  }), value$10),
            averageGrades: Js_array.map((function (value) {
                    return {
                            evaluationCriterionId: value.evaluationCriterionId,
                            averageGrade: value.averageGrade
                          };
                  }), value$11),
            milestoneTargetsCompletionStatus: Js_array.map((function (value) {
                    return {
                            id: value.id,
                            title: value.title,
                            milestoneNumber: value.milestoneNumber,
                            completed: value.completed
                          };
                  }), value$12),
            canModifyCoachNotes: value$1.canModifyCoachNotes,
            team: tmp
          },
          coachNotes: Js_array.map((function (value) {
                  var value$1 = value.author;
                  return {
                          id: value.id,
                          note: value.note,
                          createdAt: value.createdAt,
                          author: !(value$1 == null) ? User.Fragment.verifyArgsAndParse("UserFragment", value$1) : undefined
                        };
                }), value$15),
          hasArchivedCoachNotes: value.hasArchivedCoachNotes
        };
}

function serialize(value) {
  var value$1 = value.hasArchivedCoachNotes;
  var value$2 = value.coachNotes;
  var coachNotes = Js_array.map((function (value) {
          var value$1 = value.author;
          var author = value$1 !== undefined ? User.Fragment.serialize(value$1) : null;
          var value$2 = value.createdAt;
          var value$3 = value.note;
          var value$4 = value.id;
          return {
                  id: value$4,
                  note: value$3,
                  createdAt: value$2,
                  author: author
                };
        }), value$2);
  var value$3 = value.studentDetails;
  var value$4 = value$3.team;
  var team;
  if (value$4 !== undefined) {
    var value$5 = value$4.students;
    var students = Js_array.map((function (value) {
            var value$1 = value.droppedOutAt;
            var droppedOutAt = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
            var value$2 = value.personalCoaches;
            var personalCoaches = Js_array.map((function (value) {
                    return UserProxy.Fragment.serialize(value);
                  }), value$2);
            var value$3 = value.cohort;
            var cohort = Cohort.Fragment.serialize(value$3);
            var value$4 = value.user;
            var user = UserDetails.Fragment.serialize(value$4);
            var value$5 = value.taggings;
            var taggings = Js_array.map((function (value) {
                    return value;
                  }), value$5);
            var value$6 = value.id;
            return {
                    id: value$6,
                    taggings: taggings,
                    user: user,
                    cohort: cohort,
                    personalCoaches: personalCoaches,
                    droppedOutAt: droppedOutAt
                  };
          }), value$5);
    var value$6 = value$4.name;
    var value$7 = value$4.id;
    team = {
      id: value$7,
      name: value$6,
      students: students
    };
  } else {
    team = null;
  }
  var value$8 = value$3.canModifyCoachNotes;
  var value$9 = value$3.milestoneTargetsCompletionStatus;
  var milestoneTargetsCompletionStatus = Js_array.map((function (value) {
          var value$1 = value.completed;
          var value$2 = value.milestoneNumber;
          var value$3 = value.title;
          var value$4 = value.id;
          return {
                  id: value$4,
                  title: value$3,
                  milestoneNumber: value$2,
                  completed: value$1
                };
        }), value$9);
  var value$10 = value$3.averageGrades;
  var averageGrades = Js_array.map((function (value) {
          var value$1 = value.averageGrade;
          var value$2 = value.evaluationCriterionId;
          return {
                  evaluationCriterionId: value$2,
                  averageGrade: value$1
                };
        }), value$10);
  var value$11 = value$3.quizScores;
  var quizScores = Js_array.map((function (value) {
          return value;
        }), value$11);
  var value$12 = value$3.targetsCompleted;
  var value$13 = value$3.totalTargets;
  var value$14 = value$3.student;
  var value$15 = value$14.course;
  var value$16 = value$15.id;
  var course = {
    id: value$16
  };
  var value$17 = value$14.droppedOutAt;
  var droppedOutAt = value$17 !== undefined ? Caml_option.valFromOption(value$17) : null;
  var value$18 = value$14.personalCoaches;
  var personalCoaches = Js_array.map((function (value) {
          return UserProxy.Fragment.serialize(value);
        }), value$18);
  var value$19 = value$14.cohort;
  var cohort = Cohort.Fragment.serialize(value$19);
  var value$20 = value$14.user;
  var user = UserDetails.Fragment.serialize(value$20);
  var value$21 = value$14.taggings;
  var taggings = Js_array.map((function (value) {
          return value;
        }), value$21);
  var value$22 = value$14.id;
  var student = {
    id: value$22,
    taggings: taggings,
    user: user,
    cohort: cohort,
    personalCoaches: personalCoaches,
    droppedOutAt: droppedOutAt,
    course: course
  };
  var value$23 = value$3.evaluationCriteria;
  var evaluationCriteria = Js_array.map((function (value) {
          var value$1 = value.maxGrade;
          var value$2 = value.name;
          var value$3 = value.id;
          return {
                  id: value$3,
                  name: value$2,
                  maxGrade: value$1
                };
        }), value$23);
  var value$24 = value$3.email;
  var studentDetails = {
    email: value$24,
    evaluationCriteria: evaluationCriteria,
    student: student,
    totalTargets: value$13,
    targetsCompleted: value$12,
    quizScores: quizScores,
    averageGrades: averageGrades,
    milestoneTargetsCompletionStatus: milestoneTargetsCompletionStatus,
    canModifyCoachNotes: value$8,
    team: team
  };
  return {
          studentDetails: studentDetails,
          coachNotes: coachNotes,
          hasArchivedCoachNotes: value$1
        };
}

function serializeVariables(inp) {
  return {
          studentId: inp.studentId
        };
}

function makeVariables(studentId, param) {
  return {
          studentId: studentId
        };
}

var StudentDetailsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var StudentDetailsQuery_Graphql_error = include.Graphql_error;

var StudentDetailsQuery_decodeNotification = include.decodeNotification;

var StudentDetailsQuery_decodeObject = include.decodeObject;

var StudentDetailsQuery_decodeNotifications = include.decodeNotifications;

var StudentDetailsQuery_decodeErrors = include.decodeErrors;

var StudentDetailsQuery_flashNotifications = include.flashNotifications;

var StudentDetailsQuery_sendQuery = include.sendQuery;

var StudentDetailsQuery_query = include.query;

var StudentDetailsQuery_make = include.make;

var StudentDetailsQuery = {
  StudentDetailsQuery_inner: StudentDetailsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentDetailsQuery_Graphql_error,
  decodeNotification: StudentDetailsQuery_decodeNotification,
  decodeObject: StudentDetailsQuery_decodeObject,
  decodeNotifications: StudentDetailsQuery_decodeNotifications,
  decodeErrors: StudentDetailsQuery_decodeErrors,
  flashNotifications: StudentDetailsQuery_flashNotifications,
  sendQuery: StudentDetailsQuery_sendQuery,
  query: StudentDetailsQuery_query,
  $$fetch: $$fetch,
  make: StudentDetailsQuery_make
};

function getStudentDetails(studentId, setState) {
  Curry._1(setState, (function (state) {
          return {
                  selectedTab: state.selectedTab,
                  studentData: /* Loading */0,
                  submissions: state.submissions
                };
        }));
  Js_promise.then_((function (response) {
          var s = response.studentDetails.student;
          var coachNotes = response.coachNotes.map(function (coachNote) {
                return CoursesStudents__CoachNote.make(coachNote.id, coachNote.note, DateFns.decodeISO(coachNote.createdAt), Belt_Option.map(coachNote.author, User.makeFromFragment));
              });
          var evaluationCriteria = response.studentDetails.evaluationCriteria.map(function (evaluationCriterion) {
                return CoursesStudents__EvaluationCriterion.make(evaluationCriterion.id, evaluationCriterion.name, evaluationCriterion.maxGrade);
              });
          var averageGrades = response.studentDetails.averageGrades.map(function (gradeData) {
                return CoursesStudents__StudentDetails.makeAverageGrade(gradeData.evaluationCriterionId, gradeData.averageGrade);
              });
          var milestoneTargetsCompletionStatus = response.studentDetails.milestoneTargetsCompletionStatus.map(function (milestoneTarget) {
                return CoursesStudents__MilestoneTargetsCompletionStatus.make(milestoneTarget.id, milestoneTarget.title, milestoneTarget.completed, milestoneTarget.milestoneNumber);
              });
          var studentDetails = CoursesStudents__StudentDetails.make(studentId, coachNotes, response.hasArchivedCoachNotes, response.studentDetails.canModifyCoachNotes, evaluationCriteria, response.studentDetails.totalTargets, response.studentDetails.targetsCompleted, response.studentDetails.quizScores, averageGrades, CoursesStudents__StudentInfo.make(s.id, UserDetails.makeFromFragment(s.user), s.taggings, Cohort.makeFromFragment(s.cohort), Belt_Option.map(s.droppedOutAt, DateFns.decodeISO), s.personalCoaches.map(UserProxy.makeFromFragment)), Belt_Option.map(response.studentDetails.team, (function (team) {
                      return CoursesStudents__StudentDetails.makeTeam(team.id, team.name, team.students.map(function (s) {
                                      return CoursesStudents__StudentInfo.make(s.id, UserDetails.makeFromFragment(s.user), s.taggings, Cohort.makeFromFragment(s.cohort), Belt_Option.map(s.droppedOutAt, DateFns.decodeISO), s.personalCoaches.map(UserProxy.makeFromFragment));
                                    }));
                    })), response.studentDetails.student.course.id, milestoneTargetsCompletionStatus);
          Curry._1(setState, (function (state) {
                  return {
                          selectedTab: state.selectedTab,
                          studentData: /* Loaded */{
                            _0: studentDetails
                          },
                          submissions: state.submissions
                        };
                }));
          return Promise.resolve(undefined);
        }), Curry._3($$fetch, undefined, undefined, {
            studentId: studentId
          }));
}

function updateSubmissions(setState, submissions) {
  return Curry._1(setState, (function (state) {
                return {
                        selectedTab: state.selectedTab,
                        studentData: state.studentData,
                        submissions: submissions
                      };
              }));
}

function doughnutChart(color, percentage) {
  return React.createElement("svg", {
              className: "student-overlay__doughnut-chart " + color,
              viewBox: "0 0 36 36"
            }, React.createElement("path", {
                  className: "student-overlay__doughnut-chart-bg",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                }), React.createElement("path", {
                  className: "student-overlay__doughnut-chart-stroke",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
                  strokeDasharray: percentage + ", 100"
                }), React.createElement("text", {
                  className: "student-overlay__doughnut-chart-text font-semibold",
                  x: "50%",
                  y: "58%"
                }, percentage + "%"));
}

function targetsCompletionStatus(targetsCompleted, totalTargets) {
  var targetCompletionPercent = String(targetsCompleted / totalTargets * 100.0 | 0);
  return React.createElement("div", {
              "aria-label": "target-completion-status",
              className: "w-full lg:w-1/2 px-2"
            }, React.createElement("div", {
                  className: "student-overlay__doughnut-chart-container bg-gray-50"
                }, doughnutChart("purple", targetCompletionPercent), React.createElement("p", {
                      className: "text-sm font-semibold text-center mt-3"
                    }, t(undefined, undefined, "total_targets_completed")), React.createElement("p", {
                      className: "text-sm text-gray-600 font-semibold text-center mt-1"
                    }, String(targetsCompleted | 0) + ("/" + (String(totalTargets | 0) + t(undefined, undefined, "targets"))))));
}

function quizPerformanceChart(averageQuizScore, quizzesAttempted) {
  if (averageQuizScore !== undefined) {
    return React.createElement("div", {
                "aria-label": "quiz-performance-chart",
                className: "w-full lg:w-1/2 px-2 mt-2 lg:mt-0"
              }, React.createElement("div", {
                    className: "student-overlay__doughnut-chart-container"
                  }, doughnutChart("pink", String(averageQuizScore | 0)), React.createElement("p", {
                        className: "text-sm font-semibold text-center mt-3"
                      }, t(undefined, undefined, "average_quiz_score")), React.createElement("p", {
                        className: "text-sm text-gray-600 font-semibold text-center leading-tight mt-1"
                      }, t(undefined, quizzesAttempted, "quizzes_attempted"))));
  } else {
    return null;
  }
}

function milestoneTargetsCompletionStats(studentDetails) {
  var milestoneTargets = CoursesStudents__StudentDetails.milestoneTargetsCompletionStatus(studentDetails);
  var totalMilestoneTargets = milestoneTargets.length;
  var completedMilestoneTargets = milestoneTargets.filter(function (target) {
        return target.completed === true;
      }).length;
  var milestoneTargetCompletionPercentage = String(completedMilestoneTargets / totalMilestoneTargets * 100.0 | 0);
  return React.createElement("div", {
              className: "flex items-center gap-2 flex-shrink-0"
            }, React.createElement("p", {
                  className: "text-xs font-medium text-gray-500"
                }, String(completedMilestoneTargets) + " / " + String(totalMilestoneTargets), React.createElement("span", {
                      className: "px-2 text-gray-300"
                    }, "|"), ts([[
                        "percentage",
                        milestoneTargetCompletionPercentage
                      ]], undefined, "percentage_completed")), React.createElement("div", undefined, React.createElement("svg", {
                      className: "courses-milestone-complete__doughnut-chart ",
                      viewBox: "0 0 36 36"
                    }, React.createElement("path", {
                          className: "courses-milestone-complete__doughnut-chart-bg ",
                          d: "M18 2.0845\n            a 15.9155 15.9155 0 0 1 0 31.831\n            a 15.9155 15.9155 0 0 1 0 -31.831"
                        }), React.createElement("path", {
                          className: "courses-milestone-complete__doughnut-chart-stroke",
                          d: "M18 2.0845\n            a 15.9155 15.9155 0 0 1 0 31.831\n            a 15.9155 15.9155 0 0 1 0 -31.831",
                          strokeDasharray: milestoneTargetCompletionPercentage + ", 100"
                        }))));
}

function averageGradeCharts(evaluationCriteria, averageGrades) {
  return $$Array.map((function (grade) {
                var criterion = CoursesStudents__StudentDetails.evaluationCriterionForGrade(grade, evaluationCriteria, "CoursesStudents__StudentOverlay");
                return React.createElement("div", {
                            key: CoursesStudents__EvaluationCriterion.id(criterion),
                            "aria-label": "average-grade-for-criterion-" + CoursesStudents__EvaluationCriterion.id(criterion),
                            className: "flex w-full lg:w-1/2 px-2 mt-2"
                          }, React.createElement("div", {
                                className: "student-overlay__pie-chart-container"
                              }, React.createElement("div", {
                                    className: "flex px-5 pt-4 text-center items-center"
                                  }, React.createElement("svg", {
                                        className: "student-overlay__pie-chart student-overlay__pie-chart--pass",
                                        viewBox: "0 0 32 32"
                                      }, React.createElement("circle", {
                                            className: "student-overlay__pie-chart-circle student-overlay__pie-chart-circle--pass",
                                            cx: "16",
                                            cy: "16",
                                            r: "16",
                                            strokeDasharray: CoursesStudents__StudentDetails.gradeAsPercentage(grade, criterion) + ", 100"
                                          })), React.createElement("span", {
                                        className: "ms-3 text-lg font-semibold"
                                      }, grade.grade.toString() + ("/" + String(criterion.maxGrade)))), React.createElement("p", {
                                    className: "text-sm font-semibold px-5 pt-3 pb-4"
                                  }, CoursesStudents__EvaluationCriterion.name(criterion))));
              }), averageGrades);
}

function test(value, url) {
  var tester = new RegExp(value);
  return tester.test(url);
}

function socialLinkIconClass(url) {
  if (test("twitter", url)) {
    return "fab fa-twitter";
  } else if (test("facebook", url)) {
    return "fab fa-facebook-f";
  } else if (test("instagram", url)) {
    return "fab fa-instagram";
  } else if (test("youtube", url)) {
    return "fab fa-youtube";
  } else if (test("linkedin", url)) {
    return "fab fa-linkedin";
  } else if (test("reddit", url)) {
    return "fab fa-reddit";
  } else if (test("flickr", url)) {
    return "fab fa-flickr";
  } else if (test("github", url)) {
    return "fab fa-github";
  } else {
    return "fas fa-users";
  }
}

function showSocialLinks(socialLinks) {
  return React.createElement("div", {
              className: "inline-flex flex-wrap justify-center text-lg text-gray-800 mt-3 bg-gray-50 px-2 rounded-lg"
            }, $$Array.mapi((function (index, link) {
                    return React.createElement("a", {
                                key: String(index),
                                className: "px-2 py-1 inline-block hover:text-primary-500",
                                href: link,
                                target: "_blank"
                              }, React.createElement("i", {
                                    className: socialLinkIconClass(link)
                                  }));
                  }), socialLinks));
}

function setSelectedTab(selectedTab, setState) {
  return Curry._1(setState, (function (state) {
                return {
                        selectedTab: selectedTab,
                        studentData: state.studentData,
                        submissions: state.submissions
                      };
              }));
}

function addNote(setState, studentDetails, onAddCoachNotesCB, note) {
  Curry._1(onAddCoachNotesCB, undefined);
  return Curry._1(setState, (function (state) {
                return {
                        selectedTab: state.selectedTab,
                        studentData: /* Loaded */{
                          _0: CoursesStudents__StudentDetails.addNewNote(note, studentDetails)
                        },
                        submissions: state.submissions
                      };
              }));
}

function removeNote(setState, studentDetails, noteId) {
  return Curry._1(setState, (function (state) {
                return {
                        selectedTab: state.selectedTab,
                        studentData: /* Loaded */{
                          _0: CoursesStudents__StudentDetails.removeNote(noteId, studentDetails)
                        },
                        submissions: state.submissions
                      };
              }));
}

function userInfo(key, avatarUrl, name, fulltitle) {
  return React.createElement("div", {
              key: key,
              className: "shadow rounded-lg p-4 flex items-center mt-2"
            }, CoursesStudents__PersonalCoaches.avatar(undefined, avatarUrl, name), React.createElement("div", {
                  className: "ms-2 md:ms-3"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, name), React.createElement("div", {
                      className: "text-xs"
                    }, fulltitle)));
}

function coachInfo(studentDetails) {
  var coaches = CoursesStudents__StudentInfo.personalCoaches(CoursesStudents__StudentDetails.student(studentDetails));
  if (ArrayUtils.isNotEmpty(coaches)) {
    return React.createElement("div", {
                className: "mb-8"
              }, React.createElement("h6", {
                    className: "font-semibold"
                  }, t(undefined, undefined, "personal_coaches")), $$Array.map((function (coach) {
                      return userInfo(UserProxy.userId(coach), UserProxy.avatarUrl(coach), UserProxy.name(coach), UserProxy.fullTitle(coach));
                    }), coaches));
  } else {
    return null;
  }
}

function navigateToStudent(setState, _event) {
  return Curry._1(setState, (function (param) {
                return initialState;
              }));
}

function otherTeamMembers(setState, studentId, studentDetails) {
  var team = CoursesStudents__StudentDetails.team(studentDetails);
  if (team !== undefined) {
    return React.createElement("div", {
                className: "block mt-8"
              }, React.createElement("h6", {
                    className: "font-semibold"
                  }, t(undefined, undefined, "other_team_members")), CoursesStudents__StudentDetails.students(team).filter(function (student) {
                      return CoursesStudents__StudentInfo.id(student) !== studentId;
                    }).map(function (student) {
                    var path = "/students/" + (CoursesStudents__StudentInfo.id(student) + "/report");
                    return React.createElement(Link.make, {
                                href: path,
                                className: "block mt-2 rounded-lg border border-transparent hover:bg-primary-50 hover:border-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500 transition",
                                onClick: (function (param) {
                                    return Curry._1(setState, (function (param) {
                                                  return initialState;
                                                }));
                                  }),
                                children: userInfo(CoursesStudents__StudentInfo.id(student), UserDetails.avatarUrl(CoursesStudents__StudentInfo.user(student)), UserDetails.name(CoursesStudents__StudentInfo.user(student)), UserDetails.fullTitle(CoursesStudents__StudentInfo.user(student))),
                                key: CoursesStudents__StudentInfo.id(student)
                              });
                  }));
  } else {
    return null;
  }
}

function inactiveWarning(student) {
  var match = CoursesStudents__StudentInfo.droppedOutAt(student);
  var match$1 = Cohort.endsAt(CoursesStudents__StudentInfo.cohort(student));
  var warning;
  if (match !== undefined) {
    warning = t([[
            "date",
            DateFns.formatPreset(Caml_option.valFromOption(match), true, true, undefined, undefined)
          ]], undefined, "dropped_out_at");
  } else if (match$1 !== undefined) {
    var endsAt = Caml_option.valFromOption(match$1);
    warning = DateFns.isPast(endsAt) ? t([[
              "date",
              DateFns.formatPreset(endsAt, true, true, undefined, undefined)
            ]], undefined, "access_ended_at") : undefined;
  } else {
    warning = undefined;
  }
  return OptionUtils.mapWithDefault((function (warning) {
                return React.createElement("div", {
                            className: "border border-yellow-400 rounded bg-yellow-400 py-2 px-3 mt-3"
                          }, React.createElement("i", {
                                className: "fas fa-exclamation-triangle"
                              }), React.createElement("span", {
                                className: "ms-2"
                              }, warning));
              }), null, warning);
}

function onAddCoachNotesCB(studentId, setState, param) {
  getStudentDetails(studentId, setState);
}

function CoursesStudents__StudentOverlay(Props) {
  var studentId = Props.studentId;
  var userId = Props.userId;
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          ScrollLock.activate(undefined);
          return (function (param) {
                    ScrollLock.deactivate(undefined);
                  });
        }), []);
  React.useEffect((function () {
          getStudentDetails(studentId, setState);
        }), [studentId]);
  var studentDetails = state.studentData;
  var tmp;
  if (studentDetails) {
    var studentDetails$1 = studentDetails._0;
    var student = CoursesStudents__StudentDetails.student(studentDetails$1);
    var avatarUrl = UserDetails.avatarUrl(CoursesStudents__StudentInfo.user(student));
    var name = UserDetails.currentStandingName(CoursesStudents__StudentInfo.user(student));
    var name$1 = UserDetails.affiliation(CoursesStudents__StudentInfo.user(student));
    var match$1 = state.selectedTab;
    var match$2 = state.selectedTab;
    var match$3 = state.selectedTab;
    tmp = React.createElement("div", {
          className: "flex flex-col md:flex-row md:h-screen"
        }, React.createElement("div", {
              className: "w-full md:w-2/5 bg-white p-4 md:p-8 md:py-6 2xl:px-16 2xl:py-12 md:overflow-y-auto"
            }, React.createElement("div", {
                  className: "student-overlay__student-details pb-4"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "flex items-center justify-start gap-2 flex-wrap"
                        }, React.createElement("div", undefined, React.createElement("a", {
                                  "aria-label": t(undefined, undefined, "close_student_report"),
                                  className: "z-50 start-0 cursor-pointer top-0 inline-flex p-1 rounded-full bg-gray-50 h-11 w-11 justify-center items-center text-gray-600 hover:text-gray-900 hover:bg-gray-300 focus:outline-none focus:text-gray-900 focus:bg-gray-300 focus:ring-2 focus:ring-inset focus:ring-focusColor-500",
                                  title: t(undefined, undefined, "close_student_report"),
                                  href: closeOverlayLink(student)
                                }, React.createElement(Icon.make, {
                                      className: "if i-arrow-left-light if-fw text-xl lg:text-2xl"
                                    }))), React.createElement("div", undefined, React.createElement("div", {
                                  className: "student-overlay__student-avatar mx-auto w-14 h-14 md:w-16 md:h-16 text-xs border border-yellow-500 rounded-full overflow-hidden shrink-0"
                                }, avatarUrl !== undefined ? React.createElement("img", {
                                        className: "w-full object-cover",
                                        src: avatarUrl
                                      }) : React.createElement(Avatar.make, {
                                        name: UserDetails.name(CoursesStudents__StudentInfo.user(student)),
                                        className: "object-cover"
                                      }))), React.createElement("div", {
                              className: "ps-1"
                            }, React.createElement("div", undefined, React.createElement("h2", {
                                      className: "text-lg text-left font-semibold"
                                    }, UserDetails.name(CoursesStudents__StudentInfo.user(student)))), React.createElement("div", {
                                  className: "text-sm font-semibold"
                                }, UserDetails.title(CoursesStudents__StudentInfo.user(student))))), React.createElement("div", {
                          className: "mt-4 space-y-1 flex flex-col"
                        }, React.createElement("div", {
                              className: "flex flex-wrap items-center justify-normal gap-2"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, React.createElement(PfIcon.make, {
                                      className: "if i-user-regular if-fw text-xl"
                                    }), React.createElement("span", {
                                      className: "text-gray-500 font-normal"
                                    }, t(undefined, undefined, "user_id")), React.createElement(ClickToCopy.make, {
                                      copy: UserDetails.id(CoursesStudents__StudentInfo.user(student)),
                                      className: "inline-block hover:text-primary-500",
                                      children: React.createElement("span", {
                                            className: "ms-2 text-base font-semibold"
                                          }, "#" + UserDetails.id(CoursesStudents__StudentInfo.user(student)) + "")
                                    })), React.createElement("div", {
                                  className: "flex"
                                }, React.createElement(PfIcon.make, {
                                      className: "if i-academic-cap-light if-fw text-xl"
                                    }), React.createElement("span", {
                                      className: "text-gray-500 font-normal"
                                    }, t(undefined, undefined, "student_id")), React.createElement(ClickToCopy.make, {
                                      copy: CoursesStudents__StudentInfo.id(student),
                                      className: "inline-block hover:text-primary-500",
                                      children: React.createElement("span", {
                                            className: "ms-2 text-base font-semibold"
                                          }, "#" + CoursesStudents__StudentInfo.id(student) + "")
                                    }))), React.createElement("div", {
                              className: "flex"
                            }, React.createElement(PfIcon.make, {
                                  className: "if i-users-light if-fw text-xl "
                                }), React.createElement("span", {
                                  className: "text-gray-500 font-normal"
                                }, ts(undefined, undefined, "cohort")), React.createElement("span", {
                                  className: "ms-2 text-base font-semibold break-normal"
                                }, Cohort.name(CoursesStudents__StudentInfo.cohort(student)))), name !== undefined ? React.createElement("div", {
                                className: "flex"
                              }, React.createElement(PfIcon.make, {
                                    className: "if i-shield-light if-fw text-xl"
                                  }), React.createElement("span", {
                                    className: "text-gray-500 font-normal"
                                  }, ts(undefined, undefined, "user_standing.standing")), React.createElement("span", {
                                    className: "ms-2 text-base font-semibold"
                                  }, name)) : null, name$1 !== undefined ? React.createElement("div", {
                                className: "space-x-1"
                              }, React.createElement(PfIcon.make, {
                                    className: "if i-school-light if-fw text-xl"
                                  }), React.createElement("span", {
                                    className: "text-gray-500 font-normal"
                                  }, t(undefined, undefined, "affiliation")), React.createElement("span", {
                                    className: "ms-2 text-base font-semibold"
                                  }, name$1)) : null)), inactiveWarning(student)), React.createElement("div", {
                  className: "mt-8"
                }, React.createElement("h6", {
                      className: "font-semibold"
                    }, t(undefined, undefined, "targets_overview")), React.createElement("div", {
                      className: "flex -mx-2 flex-wrap mt-2"
                    }, targetsCompletionStatus(CoursesStudents__StudentDetails.targetsCompleted(studentDetails$1), CoursesStudents__StudentDetails.totalTargets(studentDetails$1)), quizPerformanceChart(CoursesStudents__StudentDetails.averageQuizScore(studentDetails$1), CoursesStudents__StudentDetails.quizzesAttempted(studentDetails$1)))), ArrayUtils.isNotEmpty(CoursesStudents__StudentDetails.averageGrades(studentDetails$1)) ? React.createElement("div", {
                    className: "mt-8"
                  }, React.createElement("h6", {
                        className: "font-semibold"
                      }, t(undefined, undefined, "average_grades")), React.createElement("div", {
                        className: "flex -mx-2 flex-wrap"
                      }, averageGradeCharts(CoursesStudents__StudentDetails.evaluationCriteria(studentDetails$1), CoursesStudents__StudentDetails.averageGrades(studentDetails$1)))) : null, coachInfo(studentDetails$1), otherTeamMembers(setState, studentId, studentDetails$1), React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("div", {
                      className: "justify-between mt-8 flex space-x-2"
                    }, React.createElement("p", {
                          className: "text-sm font-semibold"
                        }, t(undefined, undefined, "milestone_targets")), milestoneTargetsCompletionStats(studentDetails$1)), React.createElement("div", {
                      className: "space-y-2"
                    }, ArrayUtils.copyAndSort((function (a, b) {
                              return CoursesStudents__MilestoneTargetsCompletionStatus.milestoneNumber(a) - CoursesStudents__MilestoneTargetsCompletionStatus.milestoneNumber(b) | 0;
                            }), CoursesStudents__StudentDetails.milestoneTargetsCompletionStatus(studentDetails$1)).map(function (data) {
                          return React.createElement(Spread.make, {
                                      props: {
                                        "data-milestone-id": CoursesStudents__MilestoneTargetsCompletionStatus.id(data)
                                      },
                                      children: React.createElement("div", {
                                            className: "flex gap-2 mt-2 items-center p-2 rounded-md border bg-gray-100 transition"
                                          }, React.createElement("div", undefined, React.createElement("span", {
                                                    className: "text-xs font-medium inline-flex items-center " + (
                                                      CoursesStudents__MilestoneTargetsCompletionStatus.completed(data) ? "text-green-700 bg-green-100 px-1 py-0.5 rounded" : "text-orange-700 bg-orange-100 px-1 py-0.5 rounded"
                                                    )
                                                  }, React.createElement(Icon.make, {
                                                        className: CoursesStudents__MilestoneTargetsCompletionStatus.completed(data) ? "if i-check-circle-solid text-green-600" : "if i-dashed-circle-light text-orange-600"
                                                      }))), React.createElement("div", undefined, React.createElement("p", {
                                                    className: "text-sm font-semibold"
                                                  }, ts(undefined, undefined, "m") + String(CoursesStudents__MilestoneTargetsCompletionStatus.milestoneNumber(data)))), React.createElement("div", {
                                                className: "flex-1 text-sm truncate"
                                              }, CoursesStudents__MilestoneTargetsCompletionStatus.title(data)))
                                    });
                        })))), React.createElement("div", {
              className: "w-full relative md:w-3/5 bg-gray-50 md:border-s pb-20 md:pb-10 overflow-y-auto"
            }, React.createElement("div", {
                  className: "sticky top-0 bg-gray-50 pt-2 md:pt-4 px-4 md:px-8 2xl:px-16 2xl:pt-10 z-30"
                }, React.createElement("ul", {
                      className: "flex flex-1 md:flex-none p-1 md:p-0 space-x-1 md:space-x-0 text-center rounded-lg justify-between md:justify-start bg-gray-300 md:bg-transparent",
                      role: "tablist"
                    }, React.createElement("li", {
                          "aria-selected": state.selectedTab === /* Notes */0,
                          className: "cursor-pointer flex flex-1 justify-center md:flex-none rounded-md p-1.5 md:px-4 md:hover:bg-gray-50 md:py-2 text-sm font-semibold text-gray-800 hover:text-primary-600 hover:bg-gray-50 focus:outline-none focus:ring-inset focus:ring-2 focus:bg-gray-50 focus:ring-focusColor-500 md:focus:border-b-none md:focus:rounded-t-md " + (
                            match$1 ? " " : "bg-white shadow md:shadow-none rounded-md md:rounded-none md:bg-transparent md:border-b-3 hover:bg-white md:hover:bg-transparent text-primary-500 md:border-primary-500 "
                          ),
                          role: "tab",
                          tabIndex: 0,
                          onClick: (function (_event) {
                              setSelectedTab(/* Notes */0, setState);
                            })
                        }, t(undefined, undefined, "notes")), React.createElement("li", {
                          "aria-selected": state.selectedTab === /* Submissions */1,
                          className: "cursor-pointer flex flex-1 justify-center md:flex-none rounded-md p-1.5 md:px-4 md:hover:bg-gray-50 md:py-2 text-sm font-semibold text-gray-800 hover:text-primary-600 hover:bg-gray-50 focus:outline-none focus:ring-inset focus:ring-2 focus:bg-gray-50 focus:ring-focusColor-500 md:focus:border-b-none md:focus:rounded-t-md  " + (
                            match$2 ? "bg-white shadow md:shadow-none rounded-md md:rounded-none md:bg-transparent md:border-b-3 hover:bg-white md:hover:bg-transparent text-primary-500 md:border-primary-500 " : " "
                          ),
                          role: "tab",
                          tabIndex: 0,
                          onClick: (function (_event) {
                              setSelectedTab(/* Submissions */1, setState);
                            })
                        }, t(undefined, undefined, "submissions")))), React.createElement("div", {
                  className: "pt-2 px-4 md:px-8 2xl:px-16"
                }, match$3 ? React.createElement(CoursesStudents__SubmissionsList.make, {
                        studentId: studentId,
                        submissions: state.submissions,
                        updateSubmissionsCB: (function (param) {
                            return updateSubmissions(setState, param);
                          })
                      }) : React.createElement(CoursesStudents__CoachNotes.make, {
                        studentId: studentId,
                        coachNotes: CoursesStudents__StudentDetails.coachNotes(studentDetails$1),
                        hasArchivedNotes: CoursesStudents__StudentDetails.hasArchivedNotes(studentDetails$1),
                        canModifyCoachNotes: CoursesStudents__StudentDetails.canModifyCoachNotes(studentDetails$1),
                        addNoteCB: (function (param) {
                            return addNote(setState, studentDetails$1, (function (param) {
                                          return getStudentDetails(studentId, setState);
                                        }), param);
                          }),
                        removeNoteCB: (function (param) {
                            return removeNote(setState, studentDetails$1, param);
                          }),
                        userId: userId
                      }))));
  } else {
    tmp = React.createElement("div", {
          className: "flex flex-col md:flex-row md:h-screen"
        }, React.createElement("div", {
              className: "w-full md:w-2/5 bg-white p-4 md:p-8 2xl:p-16"
            }, SkeletonLoading.image(undefined), SkeletonLoading.multiple(2, SkeletonLoading.userDetails(undefined))), React.createElement("div", {
              className: "w-full relative md:w-3/5 bg-gray-50 md:border-s p-4 md:p-8 2xl:p-16"
            }, SkeletonLoading.contents(undefined), SkeletonLoading.userDetails(undefined)));
  }
  return React.createElement("div", {
              className: "fixed z-30 top-0 start-0 w-full h-full overflow-y-scroll md:overflow-hidden bg-white"
            }, tmp);
}

var UserDetailsFragment;

var CohortFragment;

var UserProxyFragment;

var UserFragment;

var make = CoursesStudents__StudentOverlay;

export {
  str ,
  t ,
  ts ,
  initialState ,
  closeOverlayLink ,
  UserDetailsFragment ,
  CohortFragment ,
  UserProxyFragment ,
  UserFragment ,
  StudentDetailsQuery ,
  getStudentDetails ,
  updateSubmissions ,
  doughnutChart ,
  targetsCompletionStatus ,
  quizPerformanceChart ,
  milestoneTargetsCompletionStats ,
  averageGradeCharts ,
  test ,
  socialLinkIconClass ,
  showSocialLinks ,
  setSelectedTab ,
  addNote ,
  removeNote ,
  userInfo ,
  coachInfo ,
  navigateToStudent ,
  otherTeamMembers ,
  inactiveWarning ,
  onAddCoachNotesCB ,
  make ,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";

function id(t) {
  return t.id;
}

function createdAt(t) {
  return t.createdAt;
}

function passedAt(t) {
  return t.passedAt;
}

function evaluatedAt(t) {
  return t.evaluatedAt;
}

function feedbackSent(t) {
  return t.feedbackSent;
}

function archivedAt(t) {
  return t.archivedAt;
}

function make(id, createdAt, passedAt, evaluatedAt, feedbackSent, archivedAt) {
  return {
          id: id,
          createdAt: createdAt,
          passedAt: passedAt,
          feedbackSent: feedbackSent,
          evaluatedAt: evaluatedAt,
          archivedAt: archivedAt
        };
}

function makeFromJs(details) {
  return Js_array.map((function (s) {
                return make(s.id, DateFns.decodeISO(s.createdAt), Belt_Option.map(s.passedAt, DateFns.decodeISO), Belt_Option.map(s.evaluatedAt, DateFns.decodeISO), s.feedbackSent, Belt_Option.map(s.archivedAt, DateFns.decodeISO));
              }), details);
}

export {
  id ,
  createdAt ,
  passedAt ,
  evaluatedAt ,
  feedbackSent ,
  archivedAt ,
  make ,
  makeFromJs ,
}
/* DateFns Not a pure module */

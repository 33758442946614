// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../../../shared/node_modules/rescript/lib/es6/string.js";
import * as Belt_List from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_List.js";
import * as Caml_array from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_array.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

function title(t) {
  return t.title;
}

function feedback(t) {
  return t.feedback;
}

function make(title, feedback) {
  return {
          title: title,
          feedback: feedback
        };
}

function makeFromJs(data) {
  return data.map(function (r) {
              return {
                      title: r.title,
                      feedback: r.feedback
                    };
            });
}

function emptyTemplate(param) {
  return [
          {
            title: "Yes",
            feedback: "Sample feedback for yes"
          },
          {
            title: "No",
            feedback: "Sample feedback for no"
          }
        ];
}

function empty(param) {
  return {
          title: "",
          feedback: undefined
        };
}

function replace(checklist, t, index) {
  return checklist.map(function (result, resultIndex) {
              if (resultIndex === index) {
                return t;
              } else {
                return result;
              }
            });
}

function updateTitle(checklist, title, t, index) {
  return replace(checklist, {
              title: title,
              feedback: t.feedback
            }, index);
}

function updateFeedback(resultItems, feedback, index) {
  var optionalFeedback = feedback.trim() === "" ? undefined : feedback;
  return replace(resultItems, {
              title: Caml_array.get(resultItems, index).title,
              feedback: optionalFeedback
            }, index);
}

function updateAdditionalFeedback(resultItems, feedback, index) {
  return replace(resultItems, {
              title: Caml_array.get(resultItems, index).title,
              feedback: feedback
            }, index);
}

function trim(t) {
  return {
          title: $$String.trim(t.title),
          feedback: t.feedback
        };
}

function encode(t) {
  var title_0 = [
    "title",
    t.title
  ];
  var title = {
    hd: title_0,
    tl: /* [] */0
  };
  var f = t.feedback;
  var feedback = f !== undefined ? ({
        hd: [
          "feedback",
          f
        ],
        tl: /* [] */0
      }) : /* [] */0;
  return Json_encode.object_(Belt_List.concat(title, feedback));
}

export {
  title ,
  feedback ,
  make ,
  makeFromJs ,
  emptyTemplate ,
  empty ,
  replace ,
  updateTitle ,
  updateFeedback ,
  updateAdditionalFeedback ,
  trim ,
  encode ,
}
/* No side effect */

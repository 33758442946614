// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

function str(prim) {
  return prim;
}

function ConfirmWindow(Props) {
  var title = Props.title;
  var body = Props.body;
  var confirmButtonText = Props.confirmButtonText;
  var cancelButtonText = Props.cancelButtonText;
  var onConfirm = Props.onConfirm;
  var onCancel = Props.onCancel;
  var disableConfirm = Props.disableConfirm;
  var alertTypeOpt = Props.alertType;
  var alertType = alertTypeOpt !== undefined ? alertTypeOpt : "Normal";
  var match = alertType === "Critical" ? [
      "bg-red-200",
      "text-red-600",
      "btn btn-danger"
    ] : [
      "bg-green-200",
      "text-green-600",
      "btn btn-success"
    ];
  return React.createElement("div", {
              className: "fixed bottom-0 inset-x-0 z-50 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
            }, React.createElement("div", {
                  className: "fixed inset-0"
                }, React.createElement("div", {
                      className: "absolute inset-0 bg-gray-800 opacity-75"
                    })), React.createElement("div", {
                  "aria-modal": true,
                  "aria-labelledby": "modal-headline",
                  className: "relative z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl sm:max-w-lg sm:w-full sm:p-6",
                  role: "dialog"
                }, React.createElement("div", {
                      className: "sm:flex sm:items-start"
                    }, React.createElement("div", {
                          className: "mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 " + match[0]
                        }, React.createElement("svg", {
                              className: "h-6 w-6 " + match[1],
                              fill: "none",
                              stroke: "currentColor",
                              viewBox: "0 0 24 24"
                            }, React.createElement("path", {
                                  d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: "2"
                                }))), React.createElement("div", {
                          className: "mt-3 sm:mt-0 ms-4"
                        }, React.createElement("h3", {
                              className: "text-lg text-center ltr:sm:text-left rtl:sm:text-right font-semibold",
                              id: "modal-headline"
                            }, title), React.createElement("div", {
                              className: "mt-2"
                            }, body))), React.createElement("div", {
                      className: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                    }, React.createElement("span", {
                          className: "flex w-full rounded-md shadow-sm ms-3 sm:w-auto"
                        }, React.createElement("button", {
                              className: match[2] + " w-full",
                              disabled: disableConfirm,
                              type: "button",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  Curry._1(onConfirm, undefined);
                                })
                            }, confirmButtonText)), React.createElement("span", {
                          className: "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                        }, React.createElement("button", {
                              className: "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-semibold text-gray-600 hover:bg-gray-50 hover:text-gray-600 focus:outline-none",
                              type: "button",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  Curry._1(onCancel, undefined);
                                })
                            }, cancelButtonText)))));
}

var make = ConfirmWindow;

export {
  str ,
  make ,
}
/* react Not a pure module */

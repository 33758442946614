// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../shared/types/User.bs.js";
import * as $$Array from "../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../shared/utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function creatorId(t) {
  return t.creatorId;
}

function editorId(t) {
  return t.editorId;
}

function body(t) {
  return t.body;
}

function replies(t) {
  return t.replies;
}

function createdAt(t) {
  return t.createdAt;
}

function editedAt(t) {
  return t.editedAt;
}

function likedByUser(t) {
  return t.likedByUser;
}

function postNumber(t) {
  return t.postNumber;
}

function totalLikes(t) {
  return t.totalLikes;
}

function solution(t) {
  return t.solution;
}

function user(users, t) {
  return Belt_Option.map(t.creatorId, (function (creatorId) {
                return ArrayUtils.unsafeFind((function (user) {
                              return User.id(user) === creatorId;
                            }), "Unable to user with id: " + (creatorId + " in TopicsShow__Post"), users);
              }));
}

function sort(posts) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.postNumber - y.postNumber | 0;
              }), posts);
}

function repliesToPost(posts, post) {
  return sort(Js_array.filter((function (p) {
                    return $$Array.mem(p.id, post.replies);
                  }), posts));
}

function addReply(newReplyId, t) {
  return {
          id: t.id,
          body: t.body,
          creatorId: t.creatorId,
          editorId: t.editorId,
          postNumber: t.postNumber,
          createdAt: t.createdAt,
          editedAt: t.editedAt,
          totalLikes: t.totalLikes,
          likedByUser: t.likedByUser,
          replies: $$Array.append([newReplyId], t.replies),
          solution: t.solution
        };
}

function addLike(t) {
  return {
          id: t.id,
          body: t.body,
          creatorId: t.creatorId,
          editorId: t.editorId,
          postNumber: t.postNumber,
          createdAt: t.createdAt,
          editedAt: t.editedAt,
          totalLikes: t.totalLikes + 1 | 0,
          likedByUser: true,
          replies: t.replies,
          solution: t.solution
        };
}

function removeLike(t) {
  return {
          id: t.id,
          body: t.body,
          creatorId: t.creatorId,
          editorId: t.editorId,
          postNumber: t.postNumber,
          createdAt: t.createdAt,
          editedAt: t.editedAt,
          totalLikes: t.totalLikes - 1 | 0,
          likedByUser: false,
          replies: t.replies,
          solution: t.solution
        };
}

function markAsSolution(replyId, replies) {
  return Js_array.map((function (reply) {
                if (reply.id === replyId) {
                  return {
                          id: reply.id,
                          body: reply.body,
                          creatorId: reply.creatorId,
                          editorId: reply.editorId,
                          postNumber: reply.postNumber,
                          createdAt: reply.createdAt,
                          editedAt: reply.editedAt,
                          totalLikes: reply.totalLikes,
                          likedByUser: reply.likedByUser,
                          replies: reply.replies,
                          solution: true
                        };
                } else {
                  return {
                          id: reply.id,
                          body: reply.body,
                          creatorId: reply.creatorId,
                          editorId: reply.editorId,
                          postNumber: reply.postNumber,
                          createdAt: reply.createdAt,
                          editedAt: reply.editedAt,
                          totalLikes: reply.totalLikes,
                          likedByUser: reply.likedByUser,
                          replies: reply.replies,
                          solution: false
                        };
                }
              }), replies);
}

function unmarkSolution(replies) {
  return Js_array.map((function (reply) {
                return {
                        id: reply.id,
                        body: reply.body,
                        creatorId: reply.creatorId,
                        editorId: reply.editorId,
                        postNumber: reply.postNumber,
                        createdAt: reply.createdAt,
                        editedAt: reply.editedAt,
                        totalLikes: reply.totalLikes,
                        likedByUser: reply.likedByUser,
                        replies: reply.replies,
                        solution: false
                      };
              }), replies);
}

function find(postId, posts) {
  return ArrayUtils.unsafeFind((function (post) {
                return post.id === postId;
              }), "Unable for find post with ID: " + (postId + " in TopicShow__Post"), posts);
}

function highestPostNumber(posts) {
  return Js_array.reduce((function (maxPostNumber, t) {
                if (t.postNumber > maxPostNumber) {
                  return t.postNumber;
                } else {
                  return maxPostNumber;
                }
              }), 0, posts);
}

function make(id, body, creatorId, editorId, postNumber, createdAt, editedAt, totalLikes, likedByUser, replies, solution) {
  return {
          id: id,
          body: body,
          creatorId: creatorId,
          editorId: editorId,
          postNumber: postNumber,
          createdAt: createdAt,
          editedAt: editedAt,
          totalLikes: totalLikes,
          likedByUser: likedByUser,
          replies: replies,
          solution: solution
        };
}

function decodeReplyId(json) {
  return Json_decode.field("id", Json_decode.string, json);
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          body: Json_decode.field("body", Json_decode.string, json),
          creatorId: Json_decode.optional((function (param) {
                  return Json_decode.field("creatorId", Json_decode.string, param);
                }), json),
          editorId: Json_decode.optional((function (param) {
                  return Json_decode.field("editorId", Json_decode.string, param);
                }), json),
          postNumber: Json_decode.field("postNumber", Json_decode.$$int, json),
          createdAt: Json_decode.field("createdAt", DateFns.decodeISO, json),
          editedAt: Json_decode.optional((function (param) {
                  return Json_decode.field("editedAt", DateFns.decodeISO, param);
                }), json),
          totalLikes: Json_decode.field("totalLikes", Json_decode.$$int, json),
          likedByUser: Json_decode.field("likedByUser", Json_decode.bool, json),
          replies: Json_decode.field("replies", (function (param) {
                  return Json_decode.array(decodeReplyId, param);
                }), json),
          solution: Json_decode.field("solution", Json_decode.bool, json)
        };
}

export {
  id ,
  creatorId ,
  editorId ,
  body ,
  replies ,
  createdAt ,
  editedAt ,
  likedByUser ,
  postNumber ,
  totalLikes ,
  solution ,
  user ,
  sort ,
  repliesToPost ,
  addReply ,
  addLike ,
  removeLike ,
  markAsSolution ,
  unmarkSolution ,
  find ,
  highestPostNumber ,
  make ,
  decodeReplyId ,
  decode ,
}
/* User Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function name(t) {
  return t.name;
}

function id(t) {
  return t.id;
}

function review(t) {
  return t.review;
}

function author(t) {
  return t.author;
}

function description(t) {
  return t.description;
}

function exited(t) {
  return t.exited;
}

function thumbnailUrl(t) {
  return t.thumbnailUrl;
}

function linkedCommunities(t) {
  return t.linkedCommunities;
}

function accessEnded(t) {
  return t.accessEnded;
}

function ended(t) {
  return t.ended;
}

function enableLeaderboard(t) {
  return t.enableLeaderboard;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          review: Json_decode.field("review", Json_decode.bool, json),
          author: Json_decode.field("author", Json_decode.bool, json),
          enableLeaderboard: Json_decode.field("enableLeaderboard", Json_decode.bool, json),
          description: Json_decode.field("description", Json_decode.string, json),
          exited: Json_decode.field("exited", Json_decode.bool, json),
          thumbnailUrl: Caml_option.null_to_opt(Json_decode.field("thumbnailUrl", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          linkedCommunities: Json_decode.field("linkedCommunities", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          accessEnded: Json_decode.field("accessEnded", Json_decode.bool, json),
          ended: Json_decode.field("ended", Json_decode.bool, json)
        };
}

export {
  name ,
  id ,
  review ,
  author ,
  description ,
  exited ,
  thumbnailUrl ,
  linkedCommunities ,
  accessEnded ,
  ended ,
  enableLeaderboard ,
  decode ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../../../shared/node_modules/rescript/lib/es6/list.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

function decode(json) {
  return {
          criterionId: Json_decode.field("criterionId", Json_decode.string, json),
          criterionName: Json_decode.field("criterionName", Json_decode.string, json),
          grade: Caml_option.null_to_opt(Json_decode.field("grade", (function (param) {
                      return Json_decode.nullable(Json_decode.$$int, param);
                    }), json))
        };
}

function grade(t) {
  return t.grade;
}

function pending(evaluation) {
  return List.exists((function (grading) {
                return grading.grade === undefined;
              }), evaluation);
}

function criterionId(t) {
  return t.criterionId;
}

function criterionName(t) {
  return t.criterionName;
}

function updateGrade(newGrade, t) {
  return {
          criterionId: t.criterionId,
          criterionName: t.criterionName,
          grade: newGrade
        };
}

function gradingEncoder(grading) {
  var grade = grading.grade;
  return Json_encode.object_({
              hd: [
                "criterionId",
                grading.criterionId
              ],
              tl: {
                hd: [
                  "grade",
                  grade !== undefined ? grade : null
                ],
                tl: /* [] */0
              }
            });
}

function make(criterionId, criterionName, grade) {
  return {
          criterionId: criterionId,
          criterionName: criterionName,
          grade: grade
        };
}

export {
  decode ,
  grade ,
  pending ,
  criterionId ,
  criterionName ,
  updateGrade ,
  gradingEncoder ,
  make ,
}
/* No side effect */

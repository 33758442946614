// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../shared/Api.bs.js";
import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as Zxcvbn from "../shared/Zxcvbn.bs.js";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import SetNewPasswordIconSvg from "./images/set-new-password-icon.svg";

var resetPasswordIcon = SetNewPasswordIconSvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.UserSessionResetPassword";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* UpdateNewPassword */0 :
        return {
                authenticityToken: state.authenticityToken,
                token: state.token,
                name: state.name,
                email: state.email,
                newPassword: action._0,
                confirmPassword: state.confirmPassword,
                saving: state.saving
              };
    case /* UpdateConfirmPassword */1 :
        return {
                authenticityToken: state.authenticityToken,
                token: state.token,
                name: state.name,
                email: state.email,
                newPassword: state.newPassword,
                confirmPassword: action._0,
                saving: state.saving
              };
    case /* UpdateSaving */2 :
        return {
                authenticityToken: state.authenticityToken,
                token: state.token,
                name: state.name,
                email: state.email,
                newPassword: state.newPassword,
                confirmPassword: state.confirmPassword,
                saving: action._0
              };
    
  }
}

function handleUpdatePasswordCB(response) {
  var path = Json_decode.field("path", (function (param) {
          return Json_decode.nullable(Json_decode.string, param);
        }), response);
  if (path !== null) {
    return DomUtils.redirect(path);
  }
  
}

function validPassword(password) {
  var length = password.length;
  if (length >= 8) {
    return length < 128;
  } else {
    return false;
  }
}

function updatePassword(state, send) {
  var payload = {};
  payload["authenticity_token"] = state.authenticityToken;
  payload["token"] = state.token;
  payload["new_password"] = state.newPassword;
  payload["confirm_password"] = state.confirmPassword;
  Curry._1(send, {
        TAG: /* UpdateSaving */2,
        _0: true
      });
  Api.create("/users/update_password", payload, handleUpdatePasswordCB, (function (param) {
          Curry._1(send, {
                TAG: /* UpdateSaving */2,
                _0: false
              });
        }));
}

function isDisabled(saving, newPassword, confirmPassword) {
  if (!validPassword(newPassword) || newPassword !== confirmPassword) {
    return true;
  } else {
    return saving;
  }
}

function submitButtonText(state) {
  var match = state.saving;
  var match$1 = state.newPassword === "";
  var match$2 = !validPassword(state.newPassword);
  var match$3 = state.confirmPassword === "";
  var match$4 = state.newPassword !== state.confirmPassword;
  if (match) {
    return t(undefined, undefined, "updating_password");
  } else if (match$1) {
    return t(undefined, undefined, "enter_new_password");
  } else if (match$2) {
    return t(undefined, undefined, "password_short");
  } else if (match$3) {
    return t(undefined, undefined, "confirm_your_password");
  } else if (match$4) {
    return t(undefined, undefined, "passwords_not_match");
  } else {
    return t(undefined, undefined, "update_password");
  }
}

function renderUpdatePassword(state, send, schoolName) {
  var inputClasses = "appearance-none h-10 mt-1 block w-full text-gray-800 border border-gray-300 rounded py-2 px-4 text-sm bg-gray-50 hover:bg-gray-50 focus:outline-none focus:bg-white focus:border-primary-400";
  var labelClasses = "inline-block tracking-wide text-gray-900 text-xs font-semibold";
  var zxcvbn = Zxcvbn.make([
        state.name,
        state.email,
        schoolName
      ], state.newPassword);
  var tmp;
  if (zxcvbn !== undefined) {
    var zxcvbn$1 = Caml_option.valFromOption(zxcvbn);
    var suggestion = ArrayUtils.getOpt(Zxcvbn.suggestions(zxcvbn$1), 0);
    tmp = React.createElement("div", {
          className: "h-5 pt-1"
        }, React.createElement("div", {
              className: "flex justify-between items-center"
            }, React.createElement("p", {
                  className: "text-xs text-gray-400 font-inter"
                }, ts(undefined, undefined, "password_strength")), React.createElement("div", {
                  className: "flex items-center gap-1 mt-1"
                }, React.createElement("span", {
                      key: "0",
                      className: "text-xs text-gray-400 pe-2 text-right rtl:text-left"
                    }, Zxcvbn.label(zxcvbn$1)), [
                    1,
                    2,
                    3,
                    4
                  ].map(function (score) {
                      return React.createElement("span", {
                                  key: String(score),
                                  className: "rounded-md h-1 " + Zxcvbn.colorClass(zxcvbn$1, score) + " w-5"
                                });
                    }))), React.createElement("div", undefined, React.createElement("ul", {
                  className: "text-yellow-900 text-[10px]"
                }, suggestion !== undefined ? React.createElement("li", undefined, React.createElement(PfIcon.make, {
                            className: "if i-info-light if-fw"
                          }), suggestion) : null)));
  } else {
    tmp = React.createElement("div", {
          className: "h-5 pt-1"
        });
  }
  return React.createElement("div", {
              className: "pt-4 pb-5 md:px-9 items-center max-w-sm mx-auto"
            }, React.createElement("div", undefined, React.createElement("label", {
                      className: labelClasses,
                      htmlFor: "new-password"
                    }, t(undefined, undefined, "new_password")), React.createElement("input", {
                      className: inputClasses,
                      id: "new-password",
                      maxLength: 128,
                      placeholder: t(undefined, undefined, "new_password_placeholder"),
                      type: "password",
                      value: state.newPassword,
                      onChange: (function ($$event) {
                          Curry._1(send, {
                                TAG: /* UpdateNewPassword */0,
                                _0: $$event.target.value
                              });
                        })
                    })), tmp, React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("label", {
                      className: labelClasses + " mt-2",
                      htmlFor: "confirm password"
                    }, t(undefined, undefined, "confirm_password")), React.createElement("input", {
                      className: inputClasses,
                      id: "confirm password",
                      maxLength: 128,
                      placeholder: t(undefined, undefined, "confirm_password_placeholder"),
                      type: "password",
                      value: state.confirmPassword,
                      onChange: (function ($$event) {
                          Curry._1(send, {
                                TAG: /* UpdateConfirmPassword */1,
                                _0: $$event.target.value
                              });
                        })
                    })), React.createElement("button", {
                  className: "btn btn-success btn-large text-center w-full mt-4",
                  disabled: isDisabled(state.saving, state.newPassword, state.confirmPassword),
                  onClick: (function (param) {
                      updatePassword(state, send);
                    })
                }, React.createElement(FaIcon.make, {
                      classes: state.saving ? "fas fa-spinner fa-spin" : "fas fa-lock"
                    }), React.createElement("span", {
                      className: "ms-2"
                    }, submitButtonText(state))));
}

function UserSessionResetPassword(Props) {
  var token = Props.token;
  var authenticityToken = Props.authenticityToken;
  var name = Props.name;
  var email = Props.email;
  var schoolName = Props.schoolName;
  var initialState = {
    authenticityToken: authenticityToken,
    token: token,
    name: name,
    email: email,
    newPassword: "",
    confirmPassword: "",
    saving: false
  };
  var match = React.useReducer(reducer, initialState);
  return React.createElement("div", {
              className: "h-full bg-gray-50 py-10 md:py-24"
            }, React.createElement("div", {
                  className: "container mx-auto max-w-lg px-4 py-6 sm:py-8 bg-white rounded-lg shadow"
                }, React.createElement("img", {
                      className: "mx-auto h-20 sm:h-32",
                      src: resetPasswordIcon
                    }), React.createElement("div", {
                      className: "text-lg sm:text-2xl font-bold text-center mt-4"
                    }, t(undefined, undefined, "set_new_password")), renderUpdatePassword(match[0], match[1], schoolName)));
}

var make = UserSessionResetPassword;

export {
  resetPasswordIcon ,
  str ,
  t ,
  ts ,
  reducer ,
  handleUpdatePasswordCB ,
  validPassword ,
  updatePassword ,
  isDisabled ,
  submitButtonText ,
  renderUpdatePassword ,
  make ,
}
/* resetPasswordIcon Not a pure module */

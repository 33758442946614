// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Tooltip from "../../../shared/components/Tooltip.bs.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as DisablingCover from "../../../shared/components/DisablingCover.bs.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__ChecklistItem from "../types/CoursesCurriculum__ChecklistItem.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";
import * as CoursesCurriculum__SubmissionItem from "./CoursesCurriculum__SubmissionItem.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionBuilder";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function buttonContents(formState, checklist) {
  var icon = formState >= 2 ? React.createElement(FaIcon.make, {
          classes: "fas fa-cloud-upload-alt me-2"
        }) : React.createElement(FaIcon.make, {
          classes: "fas fa-spinner fa-pulse me-2"
        });
  var text;
  switch (formState) {
    case /* Attaching */0 :
        text = tr(undefined, undefined, "attaching") + "...";
        break;
    case /* Saving */1 :
        text = tr(undefined, undefined, "submitting") + "...";
        break;
    case /* Ready */2 :
        text = ArrayUtils.isEmpty(checklist) ? tr(undefined, undefined, "complete") : tr(undefined, undefined, "submit");
        break;
    
  }
  return React.createElement("span", undefined, icon, text);
}

function initialState(checklist) {
  return {
          formState: /* Ready */2,
          checklist: CoursesCurriculum__ChecklistItem.fromTargetChecklistItem(checklist),
          anonymous: false
        };
}

function reducer(state, action) {
  if (typeof action !== "number") {
    return {
            formState: /* Ready */2,
            checklist: action._0,
            anonymous: state.anonymous
          };
  }
  switch (action) {
    case /* SetAttaching */0 :
        return {
                formState: /* Attaching */0,
                checklist: state.checklist,
                anonymous: state.anonymous
              };
    case /* SetSaving */1 :
        return {
                formState: /* Saving */1,
                checklist: state.checklist,
                anonymous: state.anonymous
              };
    case /* SetReady */2 :
        return {
                formState: /* Ready */2,
                checklist: state.checklist,
                anonymous: state.anonymous
              };
    case /* ToggleAnonymous */3 :
        return {
                formState: state.formState,
                checklist: state.checklist,
                anonymous: !state.anonymous
              };
    
  }
}

function isBusy(formState) {
  return formState < 2;
}

var Raw = {};

var query = "mutation CreateSubmissionMutation($targetId: ID!, $checklist: JSON!, $fileIds: [ID!]!, $anonymous: Boolean!)  {\ncreateSubmission(targetId: $targetId, checklist: $checklist, fileIds: $fileIds, anonymous: $anonymous)  {\nsubmission  {\nid  \ncreatedAt  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createSubmission;
  var value$2 = value$1.submission;
  return {
          createSubmission: {
            submission: !(value$2 == null) ? ({
                  id: value$2.id,
                  createdAt: value$2.createdAt
                }) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.createSubmission;
  var value$2 = value$1.submission;
  var submission;
  if (value$2 !== undefined) {
    var value$3 = value$2.createdAt;
    var value$4 = value$2.id;
    submission = {
      id: value$4,
      createdAt: value$3
    };
  } else {
    submission = null;
  }
  var createSubmission = {
    submission: submission
  };
  return {
          createSubmission: createSubmission
        };
}

function serializeVariables(inp) {
  var a = inp.fileIds;
  return {
          targetId: inp.targetId,
          checklist: inp.checklist,
          fileIds: Js_array.map((function (b) {
                  return b;
                }), a),
          anonymous: inp.anonymous
        };
}

function makeVariables(targetId, checklist, fileIds, anonymous, param) {
  return {
          targetId: targetId,
          checklist: checklist,
          fileIds: fileIds,
          anonymous: anonymous
        };
}

var CreateSubmissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateSubmissionQuery_Graphql_error = include.Graphql_error;

var CreateSubmissionQuery_decodeNotification = include.decodeNotification;

var CreateSubmissionQuery_decodeObject = include.decodeObject;

var CreateSubmissionQuery_decodeNotifications = include.decodeNotifications;

var CreateSubmissionQuery_decodeErrors = include.decodeErrors;

var CreateSubmissionQuery_flashNotifications = include.flashNotifications;

var CreateSubmissionQuery_sendQuery = include.sendQuery;

var CreateSubmissionQuery_query = include.query;

var CreateSubmissionQuery_fetch = include.$$fetch;

var CreateSubmissionQuery = {
  CreateSubmissionQuery_inner: CreateSubmissionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateSubmissionQuery_Graphql_error,
  decodeNotification: CreateSubmissionQuery_decodeNotification,
  decodeObject: CreateSubmissionQuery_decodeObject,
  decodeNotifications: CreateSubmissionQuery_decodeNotifications,
  decodeErrors: CreateSubmissionQuery_decodeErrors,
  flashNotifications: CreateSubmissionQuery_flashNotifications,
  sendQuery: CreateSubmissionQuery_sendQuery,
  query: CreateSubmissionQuery_query,
  $$fetch: CreateSubmissionQuery_fetch,
  make: make
};

function isButtonDisabled(state) {
  var match = state.formState;
  if (match < 2) {
    return true;
  } else {
    return !CoursesCurriculum__ChecklistItem.validChecklist(state.checklist);
  }
}

function submit(state, send, target, targetDetails, addSubmissionCB, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */1);
  var fileIds = CoursesCurriculum__ChecklistItem.fileIds(state.checklist);
  var checklist = CoursesCurriculum__ChecklistItem.encodeArray(state.checklist);
  var anonymous = state.anonymous;
  var targetId = CoursesCurriculum__Target.id(target);
  Js_promise.$$catch((function (_error) {
          Curry._1(send, /* SetReady */2);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              var submission = response.createSubmission.submission;
              if (submission !== undefined) {
                var submission$1 = Caml_option.valFromOption(submission);
                var files = CoursesCurriculum__ChecklistItem.makeFiles(state.checklist);
                var submissionChecklist = Json_decode.array((function (param) {
                        return SubmissionChecklistItem.decode(files, param);
                      }), checklist);
                var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails);
                var status = completionType !== 0 ? /* MarkedAsComplete */0 : /* Pending */1;
                Curry._1(addSubmissionCB, CoursesCurriculum__Submission.make(submission$1.id, DateFns.decodeISO(submission$1.createdAt), status, submissionChecklist, undefined));
              } else {
                Curry._1(send, /* SetReady */2);
              }
              return Promise.resolve(undefined);
            }), Curry._3(make, undefined, undefined, {
                targetId: targetId,
                checklist: checklist,
                fileIds: fileIds,
                anonymous: anonymous
              })));
}

function updateResult(state, send, index, result) {
  return Curry._1(send, /* UpdateResponse */{
              _0: CoursesCurriculum__ChecklistItem.updateResultAtIndex(index, result, state.checklist)
            });
}

function buttonClasses(checklist) {
  return "flex mt-3 " + (
          ArrayUtils.isEmpty(checklist) ? "justify-center" : "justify-end"
        );
}

function setAttaching(send, bool) {
  return Curry._1(send, bool ? /* SetAttaching */0 : /* SetReady */2);
}

function statusText(formState) {
  switch (formState) {
    case /* Attaching */0 :
        return tr(undefined, undefined, "attaching") + "...";
    case /* Saving */1 :
        return tr(undefined, undefined, "submitting") + "...";
    case /* Ready */2 :
        return tr(undefined, undefined, "submit");
    
  }
}

function tooltipText(preview) {
  if (preview) {
    return React.createElement("span", undefined, tr(undefined, undefined, "accessing_preview"), React.createElement("br", undefined), tr(undefined, undefined, "for_course"));
  } else {
    return React.createElement("span", undefined, tr(undefined, undefined, "compete_all"), React.createElement("br", undefined), tr(undefined, undefined, "steps_submit"));
  }
}

function CoursesCurriculum__SubmissionBuilder(Props) {
  var target = Props.target;
  var targetDetails = Props.targetDetails;
  var addSubmissionCB = Props.addSubmissionCB;
  var preview = Props.preview;
  var checklist = Props.checklist;
  var match = React.useReducer(reducer, initialState(checklist));
  var send = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "bg-gray-50 p-4 my-4 border rounded-lg",
              id: "submission-builder"
            }, React.createElement(DisablingCover.make, {
                  disabled: state.formState < 2,
                  message: statusText(state.formState),
                  children: null
                }, ArrayUtils.isEmpty(state.checklist) ? React.createElement("div", {
                        className: "text-center"
                      }, tr(undefined, undefined, "no_actions")) : $$Array.mapi((function (index, checklistItem) {
                          return React.createElement(CoursesCurriculum__SubmissionItem.make, {
                                      index: index,
                                      checklistItem: checklistItem,
                                      updateResultCB: (function (param) {
                                          return updateResult(state, send, index, param);
                                        }),
                                      attachingCB: (function (param) {
                                          return setAttaching(send, param);
                                        }),
                                      preview: preview,
                                      key: String(index)
                                    });
                        }), state.checklist), React.createElement("div", undefined, CoursesCurriculum__TargetDetails.discussion(targetDetails) && CoursesCurriculum__TargetDetails.allowAnonymous(targetDetails) ? React.createElement("div", undefined, React.createElement("div", {
                                className: "mt-4 flex"
                              }, React.createElement("input", {
                                    className: "hidden checkbox-input",
                                    id: "anonymous",
                                    checked: state.anonymous,
                                    type: "checkbox",
                                    onChange: (function (_event) {
                                        Curry._1(send, /* ToggleAnonymous */3);
                                      })
                                  }), React.createElement("label", {
                                    className: "checkbox-label flex items-center",
                                    htmlFor: "anonymous"
                                  }, React.createElement("span", {
                                        className: "flex-shrink-0"
                                      }, React.createElement("svg", {
                                            height: "10px",
                                            width: "12px",
                                            viewBox: "0 0 12 10"
                                          }, React.createElement("polyline", {
                                                points: "1.5 6 4.5 9 10.5 1"
                                              }))), React.createElement("span", {
                                        className: "text-sm"
                                      }, tr(undefined, undefined, "submit_anonymous_label")))), React.createElement("p", {
                                className: "text-xs ml-7 italic text-gray-700 mt-1",
                                htmlFor: "anonymous"
                              }, tr(undefined, undefined, "submit_anonymous_notice"))) : null, React.createElement("div", {
                          className: buttonClasses(state.checklist)
                        }, React.createElement(Tooltip.make, {
                              tip: tooltipText(preview),
                              position: "Start",
                              disabled: !isButtonDisabled(state),
                              children: React.createElement("button", {
                                    className: "btn btn-primary flex justify-center grow md:grow-0",
                                    disabled: isButtonDisabled(state) || preview,
                                    onClick: (function (param) {
                                        return submit(state, send, target, targetDetails, addSubmissionCB, param);
                                      })
                                  }, buttonContents(state.formState, checklist))
                            })))));
}

var make$1 = CoursesCurriculum__SubmissionBuilder;

export {
  str ,
  tr ,
  buttonContents ,
  initialState ,
  reducer ,
  isBusy ,
  CreateSubmissionQuery ,
  isButtonDisabled ,
  submit ,
  updateResult ,
  buttonClasses ,
  setAttaching ,
  statusText ,
  tooltipText ,
  make$1 as make,
}
/* include Not a pure module */

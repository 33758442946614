// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__ReactionButton";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function CoursesCurriculum__ReactionButton(Props) {
  var currentUser = Props.currentUser;
  var reactionValue = Props.reactionValue;
  var reactionDetails = Props.reactionDetails;
  var addReactionCB = Props.addReactionCB;
  var removeReactionCB = Props.removeReactionCB;
  var match = React.useState(function () {
        return false;
      });
  var setIsHovered = match[1];
  var currentUserReacted = reactionDetails.userIds.includes(CoursesCurriculum__CurrentUser.id(currentUser));
  var onMouseEnter = function ($$event) {
    $$event.preventDefault();
    Curry._1(setIsHovered, (function (param) {
            return true;
          }));
  };
  var onMouseLeave = function ($$event) {
    $$event.preventDefault();
    Curry._1(setIsHovered, (function (param) {
            return false;
          }));
  };
  return React.createElement("div", {
              className: "flex shrink-0",
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, React.createElement("button", {
                  className: "rounded-full flex items-center space-x-1 relative px-1 md:px-2 py-0.5 border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500 transition \" " + (
                    currentUserReacted ? "bg-primary-100 border-primary-300" : "bg-gray-100 border-gray-300"
                  ) + " hover:text-primary-500 hover:border-primary-500 hover:bg-gray-100 transition",
                  onClick: currentUserReacted ? Curry._1(removeReactionCB, reactionValue) : Curry._1(addReactionCB, reactionValue)
                }, React.createElement("span", {
                      className: "text-xs"
                    }, reactionValue), React.createElement("span", {
                      className: "text-xs md:text-sm"
                    }, String(reactionDetails.count))), match[0] ? React.createElement("div", {
                    className: "modal absolute z-10 bg-gray-950 text-gray-400 border p-2 mt-9 rounded space-y-1"
                  }, reactionDetails.userNames.map(function (userName) {
                        return React.createElement("div", {
                                    key: userName,
                                    className: "text-xs whitespace-nowrap font-medium"
                                  }, userName);
                      })) : null);
}

var make = CoursesCurriculum__ReactionButton;

export {
  str ,
  tr ,
  make ,
}
/* I18n Not a pure module */

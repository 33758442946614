// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function make(id, name, maxGrade) {
  return {
          id: id,
          name: name,
          maxGrade: maxGrade
        };
}

function makeFromJs(ecData) {
  return Js_array.map((function (ec) {
                return {
                        id: ec.id,
                        name: ec.name,
                        maxGrade: ec.maxGrade
                      };
              }), ecData);
}

export {
  id ,
  name ,
  make ,
  makeFromJs ,
}
/* No side effect */

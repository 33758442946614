// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as Js_promise from "../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";

var partial_arg = "components.TopicsShow__SubscriptionManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

var Raw = {};

var query = "mutation CreateTopicSubscriptionMutation($topicId: ID!)  {\ncreateTopicSubscription(topicId: $topicId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createTopicSubscription;
  return {
          createTopicSubscription: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.createTopicSubscription;
  var value$2 = value$1.success;
  var createTopicSubscription = {
    success: value$2
  };
  return {
          createTopicSubscription: createTopicSubscription
        };
}

function serializeVariables(inp) {
  return {
          topicId: inp.topicId
        };
}

function makeVariables(topicId, param) {
  return {
          topicId: topicId
        };
}

var CreateTopicSubscriptionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var CreateTopicSubscriptionQuery_Graphql_error = include.Graphql_error;

var CreateTopicSubscriptionQuery_decodeNotification = include.decodeNotification;

var CreateTopicSubscriptionQuery_decodeObject = include.decodeObject;

var CreateTopicSubscriptionQuery_decodeNotifications = include.decodeNotifications;

var CreateTopicSubscriptionQuery_decodeErrors = include.decodeErrors;

var CreateTopicSubscriptionQuery_flashNotifications = include.flashNotifications;

var CreateTopicSubscriptionQuery_sendQuery = include.sendQuery;

var CreateTopicSubscriptionQuery_query = include.query;

var CreateTopicSubscriptionQuery_make = include.make;

var CreateTopicSubscriptionQuery = {
  CreateTopicSubscriptionQuery_inner: CreateTopicSubscriptionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateTopicSubscriptionQuery_Graphql_error,
  decodeNotification: CreateTopicSubscriptionQuery_decodeNotification,
  decodeObject: CreateTopicSubscriptionQuery_decodeObject,
  decodeNotifications: CreateTopicSubscriptionQuery_decodeNotifications,
  decodeErrors: CreateTopicSubscriptionQuery_decodeErrors,
  flashNotifications: CreateTopicSubscriptionQuery_flashNotifications,
  sendQuery: CreateTopicSubscriptionQuery_sendQuery,
  query: CreateTopicSubscriptionQuery_query,
  $$fetch: $$fetch,
  make: CreateTopicSubscriptionQuery_make
};

var Raw$1 = {};

var query$1 = "mutation DeleteTopicSubscriptionMutation($topicId: ID!)  {\ndeleteTopicSubscription(topicId: $topicId)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.deleteTopicSubscription;
  return {
          deleteTopicSubscription: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.deleteTopicSubscription;
  var value$2 = value$1.success;
  var deleteTopicSubscription = {
    success: value$2
  };
  return {
          deleteTopicSubscription: deleteTopicSubscription
        };
}

function serializeVariables$1(inp) {
  return {
          topicId: inp.topicId
        };
}

function makeVariables$1(topicId, param) {
  return {
          topicId: topicId
        };
}

var DeleteTopicSubscriptionQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var DeleteTopicSubscriptionQuery_Graphql_error = include$1.Graphql_error;

var DeleteTopicSubscriptionQuery_decodeNotification = include$1.decodeNotification;

var DeleteTopicSubscriptionQuery_decodeObject = include$1.decodeObject;

var DeleteTopicSubscriptionQuery_decodeNotifications = include$1.decodeNotifications;

var DeleteTopicSubscriptionQuery_decodeErrors = include$1.decodeErrors;

var DeleteTopicSubscriptionQuery_flashNotifications = include$1.flashNotifications;

var DeleteTopicSubscriptionQuery_sendQuery = include$1.sendQuery;

var DeleteTopicSubscriptionQuery_query = include$1.query;

var DeleteTopicSubscriptionQuery_make = include$1.make;

var DeleteTopicSubscriptionQuery = {
  DeleteTopicSubscriptionQuery_inner: DeleteTopicSubscriptionQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: DeleteTopicSubscriptionQuery_Graphql_error,
  decodeNotification: DeleteTopicSubscriptionQuery_decodeNotification,
  decodeObject: DeleteTopicSubscriptionQuery_decodeObject,
  decodeNotifications: DeleteTopicSubscriptionQuery_decodeNotifications,
  decodeErrors: DeleteTopicSubscriptionQuery_decodeErrors,
  flashNotifications: DeleteTopicSubscriptionQuery_flashNotifications,
  sendQuery: DeleteTopicSubscriptionQuery_sendQuery,
  query: DeleteTopicSubscriptionQuery_query,
  $$fetch: $$fetch$1,
  make: DeleteTopicSubscriptionQuery_make
};

function iconClasses(subscribed, saving) {
  return "fa-fw" + (
          saving ? " fas fa-bell text-primary-200" : (
              subscribed ? " fas fa-bell-slash cursor-pointer text-red-400" : " far fa-bell cursor-pointer"
            )
        );
}

function handleSubscription(saving, subscribed, setSaving, topicId, subscribeCB, unsubscribeCB, $$event) {
  $$event.preventDefault();
  if (saving) {
    return ;
  } else {
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    if (subscribed) {
      Js_promise.$$catch((function (param) {
              Curry._1(setSaving, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), Js_promise.then_((function (response) {
                  if (response.deleteTopicSubscription.success) {
                    Curry._1(unsubscribeCB, undefined);
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  } else {
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  }
                  return Promise.resolve(undefined);
                }), Curry._3($$fetch$1, undefined, undefined, {
                    topicId: topicId
                  })));
    } else {
      Js_promise.$$catch((function (param) {
              Curry._1(setSaving, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), Js_promise.then_((function (response) {
                  if (response.createTopicSubscription.success) {
                    Curry._1(subscribeCB, undefined);
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  } else {
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  }
                  return Promise.resolve(undefined);
                }), Curry._3($$fetch, undefined, undefined, {
                    topicId: topicId
                  })));
    }
    return ;
  }
}

function TopicsShow__SubscriptionManager(Props) {
  var topicId = Props.topicId;
  var subscribed = Props.subscribed;
  var subscribeCB = Props.subscribeCB;
  var unsubscribeCB = Props.unsubscribeCB;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var saving = match[0];
  return React.createElement("button", {
              className: "inline-flex items-center font-semibold p-2 md:py-1 bg-gray-50 hover:bg-gray-300 border rounded text-xs shrink-0",
              disabled: saving,
              onClick: (function (param) {
                  return handleSubscription(saving, subscribed, setSaving, topicId, subscribeCB, unsubscribeCB, param);
                })
            }, React.createElement(FaIcon.make, {
                  classes: iconClasses(subscribed, saving)
                }), React.createElement("span", {
                  className: "ms-2"
                }, subscribed ? t(undefined, undefined, "unsubscribe") : t(undefined, undefined, "subscribe")));
}

var make = TopicsShow__SubscriptionManager;

export {
  t ,
  str ,
  CreateTopicSubscriptionQuery ,
  DeleteTopicSubscriptionQuery ,
  iconClasses ,
  handleSubscription ,
  make ,
}
/* include Not a pure module */

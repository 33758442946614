// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../../../shared/node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../../../shared/node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_format from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_format.js";
import * as CoursesReport__EvaluationCriterion from "./CoursesReport__EvaluationCriterion.bs.js";

function id(t) {
  return t.id;
}

function cohortName(t) {
  return t.cohortName;
}

function evaluationCriteria(t) {
  return t.evaluationCriteria;
}

function totalTargets(t) {
  return t.totalTargets;
}

function targetsPendingReview(t) {
  return t.targetsPendingReview;
}

function targetsCompleted(t) {
  return t.targetsCompleted;
}

function quizzesAttempted(t) {
  return t.quizScores.length;
}

function quizScores(t) {
  return t.quizScores;
}

function averageGrades(t) {
  return t.averageGrades;
}

function makeAverageGrade(evaluationCriterionId, grade) {
  return {
          evaluationCriterionId: evaluationCriterionId,
          grade: grade
        };
}

function milestoneTargetsCompletionStatus(t) {
  return t.milestoneTargetsCompletionStatus;
}

function evaluationCriterionForGrade(grade, evaluationCriteria) {
  return ArrayUtils.unsafeFind((function (ec) {
                return CoursesReport__EvaluationCriterion.id(ec) === grade.evaluationCriterionId;
              }), "Unable to find evaluation criterion with id: " + (grade.evaluationCriterionId + " in component: CoursesReport__Overview"), evaluationCriteria);
}

function gradeValue(averageGrade) {
  return averageGrade.grade;
}

function gradeAsPercentage(averageGrade, evaluationCriterion) {
  var maxGrade = evaluationCriterion.maxGrade;
  return String(averageGrade.grade / maxGrade * 100.0 | 0);
}

function computeAverageQuizScore(quizScores) {
  var sumOfPercentageScores = quizScores.map(function (quizScore) {
          var fractionArray = $$Array.of_list($$String.split_on_char(/* '/' */47, quizScore));
          var numerator = Caml_format.float_of_string(Caml_array.get(fractionArray, 0));
          var denominator = Caml_format.float_of_string(Caml_array.get(fractionArray, 1));
          return numerator / denominator * 100.0;
        }).reduce((function (a, b) {
          return a + b;
        }), 0.0);
  return sumOfPercentageScores / quizScores.length;
}

function averageQuizScore(t) {
  if (ArrayUtils.isEmpty(t.quizScores)) {
    return ;
  } else {
    return computeAverageQuizScore(t.quizScores);
  }
}

function make(id, cohortName, evaluationCriteria, totalTargets, targetsCompleted, quizScores, averageGrades, targetsPendingReview, milestoneTargetsCompletionStatus) {
  return {
          id: id,
          cohortName: cohortName,
          evaluationCriteria: evaluationCriteria,
          totalTargets: totalTargets,
          targetsPendingReview: targetsPendingReview,
          targetsCompleted: targetsCompleted,
          quizScores: quizScores,
          averageGrades: averageGrades,
          milestoneTargetsCompletionStatus: milestoneTargetsCompletionStatus
        };
}

export {
  id ,
  cohortName ,
  evaluationCriteria ,
  totalTargets ,
  targetsPendingReview ,
  targetsCompleted ,
  quizzesAttempted ,
  quizScores ,
  averageGrades ,
  makeAverageGrade ,
  milestoneTargetsCompletionStatus ,
  evaluationCriterionForGrade ,
  gradeValue ,
  gradeAsPercentage ,
  computeAverageQuizScore ,
  averageQuizScore ,
  make ,
}
/* ArrayUtils Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Js_promise from "../../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__SubmissionComment from "../types/CoursesCurriculum__SubmissionComment.bs.js";
import * as CoursesCurriculum__SubmissionCommentShow from "./CoursesCurriculum__SubmissionCommentShow.bs.js";

import "./CoursesCurriculum__SubmissionComments.css";
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionComments";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreateSubmissionCommentMutation($comment: String!, $submissionId: String!)  {\ncreateSubmissionComment(comment: $comment, submissionId: $submissionId)  {\ncomment  {\nid  \nuserId  \ncomment  \nsubmissionId  \nuser  {\nid  \nname  \ntitle  \navatarUrl  \n}\n\ncreatedAt  \nreactions  {\nid  \nreactionableId  \nreactionValue  \nreactionableType  \nuserName  \nupdatedAt  \n}\n\nmoderationReports  {\nid  \nuserId  \nreportableId  \nreason  \nreportableType  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createSubmissionComment;
  var value$2 = value$1.comment;
  var value$3 = value$2.user;
  var value$4 = value$3.avatarUrl;
  var value$5 = value$2.reactions;
  var value$6 = value$2.moderationReports;
  return {
          createSubmissionComment: {
            comment: {
              id: value$2.id,
              userId: value$2.userId,
              comment: value$2.comment,
              submissionId: value$2.submissionId,
              user: {
                id: value$3.id,
                name: value$3.name,
                title: value$3.title,
                avatarUrl: !(value$4 == null) ? value$4 : undefined
              },
              createdAt: value$2.createdAt,
              reactions: Js_array.map((function (value) {
                      var value$1 = value.reactionableId;
                      return {
                              id: value.id,
                              reactionableId: !(value$1 == null) ? value$1 : undefined,
                              reactionValue: value.reactionValue,
                              reactionableType: value.reactionableType,
                              userName: value.userName,
                              updatedAt: value.updatedAt
                            };
                    }), value$5),
              moderationReports: Js_array.map((function (value) {
                      var value$1 = value.reportableId;
                      return {
                              id: value.id,
                              userId: value.userId,
                              reportableId: !(value$1 == null) ? value$1 : undefined,
                              reason: value.reason,
                              reportableType: value.reportableType
                            };
                    }), value$6)
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.createSubmissionComment;
  var value$2 = value$1.comment;
  var value$3 = value$2.moderationReports;
  var moderationReports = Js_array.map((function (value) {
          var value$1 = value.reportableType;
          var value$2 = value.reason;
          var value$3 = value.reportableId;
          var reportableId = value$3 !== undefined ? value$3 : null;
          var value$4 = value.userId;
          var value$5 = value.id;
          return {
                  id: value$5,
                  userId: value$4,
                  reportableId: reportableId,
                  reason: value$2,
                  reportableType: value$1
                };
        }), value$3);
  var value$4 = value$2.reactions;
  var reactions = Js_array.map((function (value) {
          var value$1 = value.updatedAt;
          var value$2 = value.userName;
          var value$3 = value.reactionableType;
          var value$4 = value.reactionValue;
          var value$5 = value.reactionableId;
          var reactionableId = value$5 !== undefined ? value$5 : null;
          var value$6 = value.id;
          return {
                  id: value$6,
                  reactionableId: reactionableId,
                  reactionValue: value$4,
                  reactionableType: value$3,
                  userName: value$2,
                  updatedAt: value$1
                };
        }), value$4);
  var value$5 = value$2.createdAt;
  var value$6 = value$2.user;
  var value$7 = value$6.avatarUrl;
  var avatarUrl = value$7 !== undefined ? value$7 : null;
  var value$8 = value$6.title;
  var value$9 = value$6.name;
  var value$10 = value$6.id;
  var user = {
    id: value$10,
    name: value$9,
    title: value$8,
    avatarUrl: avatarUrl
  };
  var value$11 = value$2.submissionId;
  var value$12 = value$2.comment;
  var value$13 = value$2.userId;
  var value$14 = value$2.id;
  var comment = {
    id: value$14,
    userId: value$13,
    comment: value$12,
    submissionId: value$11,
    user: user,
    createdAt: value$5,
    reactions: reactions,
    moderationReports: moderationReports
  };
  var createSubmissionComment = {
    comment: comment
  };
  return {
          createSubmissionComment: createSubmissionComment
        };
}

function serializeVariables(inp) {
  return {
          comment: inp.comment,
          submissionId: inp.submissionId
        };
}

function makeVariables(comment, submissionId, param) {
  return {
          comment: comment,
          submissionId: submissionId
        };
}

var CreateSubmissionCommentMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateSubmissionCommentMutation_Graphql_error = include.Graphql_error;

var CreateSubmissionCommentMutation_decodeNotification = include.decodeNotification;

var CreateSubmissionCommentMutation_decodeObject = include.decodeObject;

var CreateSubmissionCommentMutation_decodeNotifications = include.decodeNotifications;

var CreateSubmissionCommentMutation_decodeErrors = include.decodeErrors;

var CreateSubmissionCommentMutation_flashNotifications = include.flashNotifications;

var CreateSubmissionCommentMutation_sendQuery = include.sendQuery;

var CreateSubmissionCommentMutation_query = include.query;

var CreateSubmissionCommentMutation_fetch = include.$$fetch;

var CreateSubmissionCommentMutation = {
  CreateSubmissionCommentMutation_inner: CreateSubmissionCommentMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateSubmissionCommentMutation_Graphql_error,
  decodeNotification: CreateSubmissionCommentMutation_decodeNotification,
  decodeObject: CreateSubmissionCommentMutation_decodeObject,
  decodeNotifications: CreateSubmissionCommentMutation_decodeNotifications,
  decodeErrors: CreateSubmissionCommentMutation_decodeErrors,
  flashNotifications: CreateSubmissionCommentMutation_flashNotifications,
  sendQuery: CreateSubmissionCommentMutation_sendQuery,
  query: CreateSubmissionCommentMutation_query,
  $$fetch: CreateSubmissionCommentMutation_fetch,
  make: make
};

function toggleComments(setShowComments, $$event) {
  $$event.preventDefault();
  return Curry._1(setShowComments, (function (prevState) {
                return !prevState;
              }));
}

function archiveCommentCB(setSubmissionComments, submissionComments, commentId) {
  var newComments = submissionComments.filter(function (comment) {
        return CoursesCurriculum__SubmissionComment.id(comment) !== commentId;
      });
  return Curry._1(setSubmissionComments, (function (param) {
                return newComments;
              }));
}

function CoursesCurriculum__SubmissionComments(Props) {
  var currentUser = Props.currentUser;
  var submissionId = Props.submissionId;
  var comments = Props.comments;
  var match = React.useState(function () {
        return comments;
      });
  var setSubmissionComments = match[1];
  var submissionComments = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowComments = match$1[1];
  var showComments = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setNewComment = match$2[1];
  var newComment = match$2[0];
  var commentsCount = submissionComments.length;
  var handleInputChange = function ($$event) {
    Curry._1(setNewComment, $$event.currentTarget.value);
  };
  var handleCreateSubmissionComment = function ($$event) {
    $$event.preventDefault();
    Js_promise.then_((function (response) {
            var createdComment = response.createSubmissionComment.comment;
            if (createdComment !== undefined) {
              var createdComment$1 = Caml_option.valFromOption(createdComment);
              Curry._1(setNewComment, (function (param) {
                      return "";
                    }));
              Curry._1(setSubmissionComments, (function (existingComments) {
                      return [CoursesCurriculum__SubmissionComment.decode(createdComment$1)].concat(existingComments);
                    }));
            }
            return Promise.resolve(undefined);
          }), Curry._3(make, undefined, undefined, {
              comment: newComment,
              submissionId: submissionId
            }));
  };
  return React.createElement("div", {
              className: "w-full"
            }, commentsCount !== 0 ? React.createElement("div", {
                    className: "flex items-center justify-between mx-auto"
                  }, React.createElement("div", {
                        id: "show_comments-" + submissionId
                      }, React.createElement("button", {
                            className: "flex items-center border border-gray-300 bg-white text-gray-600 px-3 py-1 font-medium text-xs leading-snug rounded-full hover:text-primary-500 hover:border-primary-500 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-600 transition",
                            onClick: (function (param) {
                                return toggleComments(setShowComments, param);
                              })
                          }, React.createElement(Icon.make, {
                                className: "if i-comment-alt-light if-fw"
                              }), React.createElement("span", {
                                className: "ps-1"
                              }, commentsCount === 1 ? "1 " + tr(undefined, undefined, "comment_text") : String(commentsCount) + " " + tr(undefined, undefined, "comments"))))) : (showComments ? undefined : Curry._1(setShowComments, (function (param) {
                          return true;
                        })), null), React.createElement(Spread.make, {
                  props: {
                    "data-submission-id": submissionId
                  },
                  children: React.createElement("div", {
                        key: submissionId,
                        className: "mt-4 space-y-8",
                        hidden: !showComments
                      }, React.createElement("div", {
                            className: "submission-comments__comment"
                          }, React.createElement("div", {
                                className: "flex gap-2 relative"
                              }, React.createElement("div", {
                                    className: "submission-comments__line flex justify-end align-start absolute h-full -left-8 -ml-[0.5px] bottom-1 w-8 "
                                  }, React.createElement("div", {
                                        className: "h-6 border-b cursor-pointer w-7 border-l border-gray-300 rounded-bl-3xl"
                                      })), React.createElement("div", {
                                    className: "w-8 h-8 shrink-0 border bg-gray-200 rounded-full flex items-center justify-center"
                                  }, CoursesCurriculum__CurrentUser.avatar(currentUser)), React.createElement("input", {
                                    className: "appearance-none block text-sm w-full bg-white leading-tight border border-gray-300 rounded px-3 py-2 focus:outline-none focus:bg-white focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                    id: "add_comment-" + submissionId,
                                    autoFocus: true,
                                    maxLength: 255,
                                    placeholder: tr(undefined, undefined, "write_comment"),
                                    type: "text",
                                    value: newComment,
                                    onChange: handleInputChange
                                  }), React.createElement("button", {
                                    className: "btn btn-primary text-sm",
                                    disabled: newComment === "",
                                    onClick: handleCreateSubmissionComment
                                  }, tr(undefined, undefined, "comment_button")))), submissionComments.map(function (comment) {
                            return React.createElement("div", {
                                        key: "comment-" + CoursesCurriculum__SubmissionComment.id(comment),
                                        className: "submission-comments__comment"
                                      }, React.createElement(CoursesCurriculum__SubmissionCommentShow.make, {
                                            currentUser: currentUser,
                                            comment: comment,
                                            archiveCommentCB: (function (param) {
                                                return archiveCommentCB(setSubmissionComments, submissionComments, param);
                                              })
                                          }));
                          }))
                }));
}

var make$1 = CoursesCurriculum__SubmissionComments;

export {
  str ,
  tr ,
  CreateSubmissionCommentMutation ,
  toggleComments ,
  archiveCommentCB ,
  make$1 as make,
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../utils/DateFns.bs.js";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function fullTitle(t) {
  return t.fullTitle;
}

function lastSeenAt(t) {
  return t.lastSeenAt;
}

function taggings(t) {
  return t.taggings;
}

function affiliation(t) {
  return t.affiliation;
}

function title(t) {
  return t.title;
}

function currentStandingName(t) {
  return t.currentStandingName;
}

var Raw = {};

function parse(value) {
  var value$1 = value.affiliation;
  var value$2 = value.avatarUrl;
  var value$3 = value.taggings;
  var value$4 = value.lastSeenAt;
  var value$5 = value.currentStandingName;
  return {
          id: value.id,
          name: value.name,
          title: value.title,
          affiliation: !(value$1 == null) ? value$1 : undefined,
          fullTitle: value.fullTitle,
          avatarUrl: !(value$2 == null) ? value$2 : undefined,
          taggings: Js_array.map((function (value) {
                  return value;
                }), value$3),
          lastSeenAt: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
          currentStandingName: !(value$5 == null) ? value$5 : undefined
        };
}

function serialize(value) {
  var value$1 = value.currentStandingName;
  var currentStandingName = value$1 !== undefined ? value$1 : null;
  var value$2 = value.lastSeenAt;
  var lastSeenAt = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
  var value$3 = value.taggings;
  var taggings = Js_array.map((function (value) {
          return value;
        }), value$3);
  var value$4 = value.avatarUrl;
  var avatarUrl = value$4 !== undefined ? value$4 : null;
  var value$5 = value.fullTitle;
  var value$6 = value.affiliation;
  var affiliation = value$6 !== undefined ? value$6 : null;
  var value$7 = value.title;
  var value$8 = value.name;
  var value$9 = value.id;
  return {
          id: value$9,
          name: value$8,
          title: value$7,
          affiliation: affiliation,
          fullTitle: value$5,
          avatarUrl: avatarUrl,
          taggings: taggings,
          lastSeenAt: lastSeenAt,
          currentStandingName: currentStandingName
        };
}

function verifyArgsAndParse(_UserDetailsFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Fragment = {
  Raw: Raw,
  query: "fragment UserDetailsFragment on User   {\nid  \nname  \ntitle  \naffiliation  \nfullTitle  \navatarUrl  \ntaggings  \nlastSeenAt  \ncurrentStandingName  \n}\n",
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

function makeFromFragment(user) {
  return {
          id: user.id,
          name: user.name,
          title: user.title,
          affiliation: user.affiliation,
          fullTitle: user.fullTitle,
          avatarUrl: user.avatarUrl,
          taggings: user.taggings,
          lastSeenAt: Belt_Option.map(user.lastSeenAt, DateFns.decodeISO),
          currentStandingName: user.currentStandingName
        };
}

function makeFromJs(jsObject) {
  return {
          id: jsObject.id,
          name: jsObject.name,
          title: jsObject.title,
          affiliation: jsObject.affiliation,
          fullTitle: jsObject.fullTitle,
          avatarUrl: jsObject.avatarUrl,
          taggings: jsObject.taggings,
          lastSeenAt: Belt_Option.map(jsObject.lastSeenAt, DateFns.decodeISO),
          currentStandingName: jsObject.currentStandingName
        };
}

export {
  id ,
  name ,
  avatarUrl ,
  fullTitle ,
  lastSeenAt ,
  taggings ,
  affiliation ,
  title ,
  currentStandingName ,
  Fragment ,
  makeFromFragment ,
  makeFromJs ,
}
/* DateFns Not a pure module */

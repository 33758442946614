// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function isAdmin(t) {
  return t.isAdmin;
}

function isAuthor(t) {
  return t.isAuthor;
}

function isCoach(t) {
  return t.isCoach;
}

function isStudent(t) {
  return t.isStudent;
}

function isModerator(t) {
  if (t.isAdmin) {
    return true;
  } else {
    return t.isCoach;
  }
}

function isParticipant(t) {
  if (t.isAdmin || t.isAuthor || t.isCoach) {
    return true;
  } else {
    return t.isStudent;
  }
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          isAdmin: Json_decode.field("isAdmin", Json_decode.bool, json),
          isAuthor: Json_decode.field("isAuthor", Json_decode.bool, json),
          isCoach: Json_decode.field("isCoach", Json_decode.bool, json),
          isStudent: Json_decode.field("isStudent", Json_decode.bool, json)
        };
}

function avatar(t) {
  return Belt_Option.mapWithDefault(t.avatarUrl, React.createElement(Avatar.make, {
                  name: t.name,
                  className: "w-full h-full"
                }), (function (src) {
                return React.createElement("img", {
                            src: src
                          });
              }));
}

export {
  id ,
  name ,
  avatarUrl ,
  isAdmin ,
  isAuthor ,
  isCoach ,
  isStudent ,
  isModerator ,
  isParticipant ,
  decode ,
  avatar ,
}
/* react Not a pure module */

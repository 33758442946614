// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as Curry from "../../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../../node_modules/bs-fetch/src/Fetch.bs.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Caml_obj from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as LoadingV2 from "../../../shared/types/LoadingV2.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as Pagination from "../../../shared/utils/Pagination.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as ScrollLock from "../../../shared/utils/ScrollLock.bs.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as LoadingSpinner from "../../../shared/components/LoadingSpinner.bs.js";
import * as Caml_exceptions from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_exceptions.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as AuthenticityToken from "../../../shared/utils/AuthenticityToken.bs.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesCurriculum__Quiz from "./CoursesCurriculum__Quiz.bs.js";
import * as CoursesCurriculum__User from "../types/CoursesCurriculum__User.bs.js";
import * as CoursesCurriculum__Learn from "./CoursesCurriculum__Learn.bs.js";
import * as CoursesCurriculum__Course from "../types/CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Discuss from "./CoursesCurriculum__Discuss.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";
import NoPeerSubmissionsSvg from "../images/no-peer-submissions.svg";
import * as CoursesCurriculum__LatestSubmission from "../types/CoursesCurriculum__LatestSubmission.bs.js";
import * as CoursesCurriculum__DiscussSubmission from "./CoursesCurriculum__DiscussSubmission.bs.js";
import * as CoursesCurriculum__SubmissionBuilder from "./CoursesCurriculum__SubmissionBuilder.bs.js";
import * as CoursesCurriculum__DiscussionSubmission from "../types/CoursesCurriculum__DiscussionSubmission.bs.js";
import AssignmentDiscussionIconSvg from "../images/assignment-discussion-icon.svg";
import * as CoursesCurriculum__CompletionInstructions from "./CoursesCurriculum__CompletionInstructions.bs.js";
import * as CoursesCurriculum__SubmissionsAndFeedback from "./CoursesCurriculum__SubmissionsAndFeedback.bs.js";

var UnexpectedSubmissionStatus = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Overlay.UnexpectedSubmissionStatus");

var UnexpectedResponse = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Overlay.UnexpectedResponse");

import "./CoursesCurriculum__Overlay.css"
;

var noPeerSubmissionIcon = NoPeerSubmissionsSvg;

var assignmentDiscussionIcon = AssignmentDiscussionIconSvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__Overlay";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Item = {};

var PagedSubmission = Pagination.Make(Item);

var initialState_loading = LoadingV2.empty(undefined);

var initialState = {
  loading: initialState_loading,
  targetDetails: undefined,
  tab: /* Learn */0,
  peerSubmissions: /* Unloaded */0,
  totalEntriesCount: 0
};

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ResetState */0 :
      case /* PerformQuickNavigation */1 :
          return initialState;
      case /* BeginReloading */2 :
          return {
                  loading: LoadingV2.setReloading(state.loading),
                  targetDetails: state.targetDetails,
                  tab: state.tab,
                  peerSubmissions: state.peerSubmissions,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* BeginLoadingMore */3 :
          return {
                  loading: /* LoadingMore */0,
                  targetDetails: state.targetDetails,
                  tab: state.tab,
                  peerSubmissions: state.peerSubmissions,
                  totalEntriesCount: state.totalEntriesCount
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* Select */0 :
          return {
                  loading: state.loading,
                  targetDetails: state.targetDetails,
                  tab: action._0,
                  peerSubmissions: state.peerSubmissions,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* SetTargetDetails */1 :
          return {
                  loading: state.loading,
                  targetDetails: action._0,
                  tab: state.tab,
                  peerSubmissions: state.peerSubmissions,
                  totalEntriesCount: state.totalEntriesCount
                };
      case /* AddSubmission */2 :
          if (action._0) {
            return {
                    loading: state.loading,
                    targetDetails: OptionUtils.map(CoursesCurriculum__TargetDetails.clearPendingUserIds, state.targetDetails),
                    tab: state.tab,
                    peerSubmissions: state.peerSubmissions,
                    totalEntriesCount: state.totalEntriesCount
                  };
          } else {
            return state;
          }
      case /* LoadSubmissions */3 :
          var newSubmissions = action._2;
          var match = state.loading;
          var updatedSubmissions = match ? newSubmissions : Curry._1(PagedSubmission.toArray, state.peerSubmissions).concat(newSubmissions);
          return {
                  loading: LoadingV2.setNotLoading(state.loading),
                  targetDetails: state.targetDetails,
                  tab: state.tab,
                  peerSubmissions: Curry._3(PagedSubmission.make, updatedSubmissions, action._1, action._0),
                  totalEntriesCount: action._3
                };
      
    }
  }
}

function closeOverlay(course) {
  RescriptReactRouter.push("/courses/" + (CoursesCurriculum__Course.id(course) + "/curriculum"));
}

var Raw = {};

var query = "query DiscussionSubmissionsQuery($targetId: ID!, $after: String)  {\ndiscussionSubmissions(targetId: $targetId, first: 10, after: $after)  {\nnodes  {\nid  \ntargetId  \ncreatedAt  \nhiddenAt  \nchecklist  \nfiles  {\nid  \ntitle  \nurl  \n}\n\nuserNames  \nusers  {\nid  \nname  \ntitle  \navatarUrl  \n}\n\nteamName  \ncomments  {\nid  \nuserId  \nsubmissionId  \ncomment  \nreactions  {\nid  \nuserId  \nreactionableId  \nreactionValue  \nreactionableType  \nuserName  \nupdatedAt  \n}\n\nmoderationReports  {\nid  \nuserId  \nreportableId  \nreason  \nreportableType  \n}\n\nuser  {\nid  \nname  \ntitle  \navatarUrl  \n}\n\ncreatedAt  \nhiddenAt  \nhiddenById  \n}\n\nreactions  {\nid  \nuserId  \nreactionableId  \nreactionValue  \nreactionableType  \nuserName  \nupdatedAt  \n}\n\nanonymous  \npinned  \nmoderationReports  {\nid  \nuserId  \nreportableId  \nreason  \nreportableType  \n}\n\n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.discussionSubmissions;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  return {
          discussionSubmissions: {
            nodes: Js_array.map((function (value) {
                    var value$1 = value.hiddenAt;
                    var value$2 = value.files;
                    var value$3 = value.users;
                    var value$4 = value.teamName;
                    var value$5 = value.comments;
                    var value$6 = value.reactions;
                    var value$7 = value.moderationReports;
                    return {
                            id: value.id,
                            targetId: value.targetId,
                            createdAt: value.createdAt,
                            hiddenAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                            checklist: value.checklist,
                            files: Js_array.map((function (value) {
                                    return {
                                            id: value.id,
                                            title: value.title,
                                            url: value.url
                                          };
                                  }), value$2),
                            userNames: value.userNames,
                            users: Js_array.map((function (value) {
                                    var value$1 = value.avatarUrl;
                                    return {
                                            id: value.id,
                                            name: value.name,
                                            title: value.title,
                                            avatarUrl: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$3),
                            teamName: !(value$4 == null) ? value$4 : undefined,
                            comments: !(value$5 == null) ? Js_array.map((function (value) {
                                      var value$1 = value.reactions;
                                      var value$2 = value.moderationReports;
                                      var value$3 = value.user;
                                      var value$4 = value$3.avatarUrl;
                                      var value$5 = value.hiddenAt;
                                      var value$6 = value.hiddenById;
                                      return {
                                              id: value.id,
                                              userId: value.userId,
                                              submissionId: value.submissionId,
                                              comment: value.comment,
                                              reactions: Js_array.map((function (value) {
                                                      var value$1 = value.reactionableId;
                                                      return {
                                                              id: value.id,
                                                              userId: value.userId,
                                                              reactionableId: !(value$1 == null) ? value$1 : undefined,
                                                              reactionValue: value.reactionValue,
                                                              reactionableType: value.reactionableType,
                                                              userName: value.userName,
                                                              updatedAt: value.updatedAt
                                                            };
                                                    }), value$1),
                                              moderationReports: Js_array.map((function (value) {
                                                      var value$1 = value.reportableId;
                                                      return {
                                                              id: value.id,
                                                              userId: value.userId,
                                                              reportableId: !(value$1 == null) ? value$1 : undefined,
                                                              reason: value.reason,
                                                              reportableType: value.reportableType
                                                            };
                                                    }), value$2),
                                              user: {
                                                id: value$3.id,
                                                name: value$3.name,
                                                title: value$3.title,
                                                avatarUrl: !(value$4 == null) ? value$4 : undefined
                                              },
                                              createdAt: value.createdAt,
                                              hiddenAt: !(value$5 == null) ? Caml_option.some(value$5) : undefined,
                                              hiddenById: !(value$6 == null) ? value$6 : undefined
                                            };
                                    }), value$5) : undefined,
                            reactions: !(value$6 == null) ? Js_array.map((function (value) {
                                      var value$1 = value.reactionableId;
                                      return {
                                              id: value.id,
                                              userId: value.userId,
                                              reactionableId: !(value$1 == null) ? value$1 : undefined,
                                              reactionValue: value.reactionValue,
                                              reactionableType: value.reactionableType,
                                              userName: value.userName,
                                              updatedAt: value.updatedAt
                                            };
                                    }), value$6) : undefined,
                            anonymous: value.anonymous,
                            pinned: value.pinned,
                            moderationReports: Js_array.map((function (value) {
                                    var value$1 = value.reportableId;
                                    return {
                                            id: value.id,
                                            userId: value.userId,
                                            reportableId: !(value$1 == null) ? value$1 : undefined,
                                            reason: value.reason,
                                            reportableType: value.reportableType
                                          };
                                  }), value$7)
                          };
                  }), value$2),
            pageInfo: {
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$3.hasNextPage
            },
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.discussionSubmissions;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var pageInfo = {
    endCursor: endCursor,
    hasNextPage: value$4
  };
  var value$6 = value$1.nodes;
  var nodes = Js_array.map((function (value) {
          var value$1 = value.moderationReports;
          var moderationReports = Js_array.map((function (value) {
                  var value$1 = value.reportableType;
                  var value$2 = value.reason;
                  var value$3 = value.reportableId;
                  var reportableId = value$3 !== undefined ? value$3 : null;
                  var value$4 = value.userId;
                  var value$5 = value.id;
                  return {
                          id: value$5,
                          userId: value$4,
                          reportableId: reportableId,
                          reason: value$2,
                          reportableType: value$1
                        };
                }), value$1);
          var value$2 = value.pinned;
          var value$3 = value.anonymous;
          var value$4 = value.reactions;
          var reactions = value$4 !== undefined ? Js_array.map((function (value) {
                    var value$1 = value.updatedAt;
                    var value$2 = value.userName;
                    var value$3 = value.reactionableType;
                    var value$4 = value.reactionValue;
                    var value$5 = value.reactionableId;
                    var reactionableId = value$5 !== undefined ? value$5 : null;
                    var value$6 = value.userId;
                    var value$7 = value.id;
                    return {
                            id: value$7,
                            userId: value$6,
                            reactionableId: reactionableId,
                            reactionValue: value$4,
                            reactionableType: value$3,
                            userName: value$2,
                            updatedAt: value$1
                          };
                  }), value$4) : null;
          var value$5 = value.comments;
          var comments = value$5 !== undefined ? Js_array.map((function (value) {
                    var value$1 = value.hiddenById;
                    var hiddenById = value$1 !== undefined ? value$1 : null;
                    var value$2 = value.hiddenAt;
                    var hiddenAt = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
                    var value$3 = value.createdAt;
                    var value$4 = value.user;
                    var value$5 = value$4.avatarUrl;
                    var avatarUrl = value$5 !== undefined ? value$5 : null;
                    var value$6 = value$4.title;
                    var value$7 = value$4.name;
                    var value$8 = value$4.id;
                    var user = {
                      id: value$8,
                      name: value$7,
                      title: value$6,
                      avatarUrl: avatarUrl
                    };
                    var value$9 = value.moderationReports;
                    var moderationReports = Js_array.map((function (value) {
                            var value$1 = value.reportableType;
                            var value$2 = value.reason;
                            var value$3 = value.reportableId;
                            var reportableId = value$3 !== undefined ? value$3 : null;
                            var value$4 = value.userId;
                            var value$5 = value.id;
                            return {
                                    id: value$5,
                                    userId: value$4,
                                    reportableId: reportableId,
                                    reason: value$2,
                                    reportableType: value$1
                                  };
                          }), value$9);
                    var value$10 = value.reactions;
                    var reactions = Js_array.map((function (value) {
                            var value$1 = value.updatedAt;
                            var value$2 = value.userName;
                            var value$3 = value.reactionableType;
                            var value$4 = value.reactionValue;
                            var value$5 = value.reactionableId;
                            var reactionableId = value$5 !== undefined ? value$5 : null;
                            var value$6 = value.userId;
                            var value$7 = value.id;
                            return {
                                    id: value$7,
                                    userId: value$6,
                                    reactionableId: reactionableId,
                                    reactionValue: value$4,
                                    reactionableType: value$3,
                                    userName: value$2,
                                    updatedAt: value$1
                                  };
                          }), value$10);
                    var value$11 = value.comment;
                    var value$12 = value.submissionId;
                    var value$13 = value.userId;
                    var value$14 = value.id;
                    return {
                            id: value$14,
                            userId: value$13,
                            submissionId: value$12,
                            comment: value$11,
                            reactions: reactions,
                            moderationReports: moderationReports,
                            user: user,
                            createdAt: value$3,
                            hiddenAt: hiddenAt,
                            hiddenById: hiddenById
                          };
                  }), value$5) : null;
          var value$6 = value.teamName;
          var teamName = value$6 !== undefined ? value$6 : null;
          var value$7 = value.users;
          var users = Js_array.map((function (value) {
                  var value$1 = value.avatarUrl;
                  var avatarUrl = value$1 !== undefined ? value$1 : null;
                  var value$2 = value.title;
                  var value$3 = value.name;
                  var value$4 = value.id;
                  return {
                          id: value$4,
                          name: value$3,
                          title: value$2,
                          avatarUrl: avatarUrl
                        };
                }), value$7);
          var value$8 = value.userNames;
          var value$9 = value.files;
          var files = Js_array.map((function (value) {
                  var value$1 = value.url;
                  var value$2 = value.title;
                  var value$3 = value.id;
                  return {
                          id: value$3,
                          title: value$2,
                          url: value$1
                        };
                }), value$9);
          var value$10 = value.checklist;
          var value$11 = value.hiddenAt;
          var hiddenAt = value$11 !== undefined ? Caml_option.valFromOption(value$11) : null;
          var value$12 = value.createdAt;
          var value$13 = value.targetId;
          var value$14 = value.id;
          return {
                  id: value$14,
                  targetId: value$13,
                  createdAt: value$12,
                  hiddenAt: hiddenAt,
                  checklist: value$10,
                  files: files,
                  userNames: value$8,
                  users: users,
                  teamName: teamName,
                  comments: comments,
                  reactions: reactions,
                  anonymous: value$3,
                  pinned: value$2,
                  moderationReports: moderationReports
                };
        }), value$6);
  var discussionSubmissions = {
    nodes: nodes,
    pageInfo: pageInfo,
    totalCount: value$2
  };
  return {
          discussionSubmissions: discussionSubmissions
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  return {
          targetId: inp.targetId,
          after: a !== undefined ? a : undefined
        };
}

function makeVariables(targetId, after, param) {
  return {
          targetId: targetId,
          after: after
        };
}

var DiscussionSubmissionsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var DiscussionSubmissionsQuery_Graphql_error = include.Graphql_error;

var DiscussionSubmissionsQuery_decodeNotification = include.decodeNotification;

var DiscussionSubmissionsQuery_decodeObject = include.decodeObject;

var DiscussionSubmissionsQuery_decodeNotifications = include.decodeNotifications;

var DiscussionSubmissionsQuery_decodeErrors = include.decodeErrors;

var DiscussionSubmissionsQuery_flashNotifications = include.flashNotifications;

var DiscussionSubmissionsQuery_sendQuery = include.sendQuery;

var DiscussionSubmissionsQuery_query = include.query;

var DiscussionSubmissionsQuery_fetch = include.$$fetch;

var DiscussionSubmissionsQuery = {
  DiscussionSubmissionsQuery_inner: DiscussionSubmissionsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: DiscussionSubmissionsQuery_Graphql_error,
  decodeNotification: DiscussionSubmissionsQuery_decodeNotification,
  decodeObject: DiscussionSubmissionsQuery_decodeObject,
  decodeNotifications: DiscussionSubmissionsQuery_decodeNotifications,
  decodeErrors: DiscussionSubmissionsQuery_decodeErrors,
  flashNotifications: DiscussionSubmissionsQuery_flashNotifications,
  sendQuery: DiscussionSubmissionsQuery_sendQuery,
  query: DiscussionSubmissionsQuery_query,
  $$fetch: DiscussionSubmissionsQuery_fetch,
  make: make
};

function getDiscussionSubmissions(send, cursor, targetId) {
  Js_promise.then_((function (response) {
          Curry._1(send, {
                TAG: /* LoadSubmissions */3,
                _0: response.discussionSubmissions.pageInfo.endCursor,
                _1: response.discussionSubmissions.pageInfo.hasNextPage,
                _2: Js_array.map(CoursesCurriculum__DiscussionSubmission.decode, response.discussionSubmissions.nodes),
                _3: response.discussionSubmissions.totalCount
              });
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            targetId: targetId,
            after: cursor
          }));
}

function reloadSubmissions(send, targetId) {
  Curry._1(send, /* BeginReloading */2);
  getDiscussionSubmissions(send, undefined, targetId);
}

function loadTargetDetails(target, currentUser, send, param) {
  Js_promise.then_((function (json) {
          var targetDetails = CoursesCurriculum__TargetDetails.decode(json);
          Curry._1(send, {
                TAG: /* SetTargetDetails */1,
                _0: targetDetails
              });
          if (CoursesCurriculum__CurrentUser.isParticipant(currentUser) && CoursesCurriculum__TargetDetails.discussion(targetDetails)) {
            reloadSubmissions(send, CoursesCurriculum__Target.id(target));
          }
          return Promise.resolve(targetDetails);
        }), Js_promise.then_(Fetch.$$Response.json, fetch("/targets/" + (CoursesCurriculum__Target.id(target) + "/details_v2"))));
}

function submissionsLoadedData(totalSubmissionsCount, loadedSubmissionsCount) {
  return React.createElement("p", {
              className: "inline-block mt-8 mx-auto text-gray-800 text-xs px-2 text-center font-semibold",
              tabIndex: 0
            }, totalSubmissionsCount === loadedSubmissionsCount ? t(undefined, loadedSubmissionsCount, "submissions_fully_loaded_text") : t([
                    [
                      "total_submissions",
                      String(totalSubmissionsCount)
                    ],
                    [
                      "loaded_submissions_count",
                      String(loadedSubmissionsCount)
                    ]
                  ], loadedSubmissionsCount, "submissions_partially_loaded_text"));
}

function submissionsList(submissions, state, currentUser, callBack) {
  return React.createElement("div", {
              className: "discussion-submissions__container space-y-16"
            }, ArrayUtils.isEmpty(submissions) ? React.createElement("div", {
                    className: "bg-gray-50/50 rounded-lg mt-2 p-4"
                  }, React.createElement("img", {
                        className: "w-64 mx-auto",
                        src: noPeerSubmissionIcon
                      }), React.createElement("p", {
                        className: "text-center text-gray-600"
                      }, t(undefined, undefined, "no_peer_submissions"))) : submissions.map(function (submission) {
                    return React.createElement(CoursesCurriculum__DiscussSubmission.make, {
                                currentUser: currentUser,
                                submission: submission,
                                callBack: callBack,
                                key: CoursesCurriculum__DiscussionSubmission.id(submission)
                              });
                  }), ReactUtils.nullIf(React.createElement("div", {
                      className: "text-center pb-4"
                    }, submissionsLoadedData(state.totalEntriesCount, submissions.length)), ArrayUtils.isEmpty(submissions)));
}

function completionTypeToString(completionType, targetStatus) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var exit = 0;
  if (typeof match === "number") {
    if (match !== 0) {
      exit = 2;
    } else {
      switch (completionType) {
        case /* Evaluated */0 :
            return t(undefined, undefined, "completion_tab_complete");
        case /* TakeQuiz */1 :
            return t(undefined, undefined, "completion_tab_take_quiz");
        case /* SubmitForm */2 :
            return t(undefined, undefined, "completion_tab_submit_form");
        case /* NoAssignment */3 :
            return "";
        
      }
    }
  } else {
    var match$1 = match._0;
    if (typeof match$1 === "number" && match$1 < 2) {
      exit = 2;
    }
    
  }
  if (exit === 2) {
    switch (completionType) {
      case /* Evaluated */0 :
          return t(undefined, undefined, "completion_tab_submissions");
      case /* TakeQuiz */1 :
          return t(undefined, undefined, "completion_tab_quiz_result");
      case /* SubmitForm */2 :
          return t(undefined, undefined, "completion_tab_form_response");
      case /* NoAssignment */3 :
          break;
      
    }
  }
  if (typeof match === "number") {
    return t(undefined, undefined, "completion_tab_completed");
  } else {
    return t(undefined, undefined, "completion_tab_locked");
  }
}

function tabToString(targetStatus, tab) {
  if (typeof tab === "number") {
    if (tab !== 0) {
      return t(undefined, undefined, "discuss_tab");
    } else {
      return t(undefined, undefined, "learn_tab");
    }
  } else {
    return completionTypeToString(tab._0, targetStatus);
  }
}

function selectableTabs(targetDetails) {
  if (Caml_obj.equal(CoursesCurriculum__TargetDetails.communities(targetDetails), [])) {
    return [/* Learn */0];
  } else {
    return [
            /* Learn */0,
            /* Discuss */1
          ];
  }
}

function tabClasses(selection, tab) {
  return "course-overlay__body-tab-item p-2 md:px-3 md:py-4 flex w-full items-center justify-center text-sm -mx-px font-semibold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500" + (
          Caml_obj.equal(tab, selection) ? " course-overlay__body-tab-item--selected" : " bg-gray-50 hover:text-primary-400 hover:bg-gray-50 focus:text-primary-400 focus:bg-gray-50 cursor-pointer"
        );
}

function scrollCompleteButtonIntoViewEventually(param) {
  setTimeout((function (param) {
          var element = document.getElementById("auto-verify-target");
          if (element == null) {
            Rollbar.error("Could not find the 'Complete' button to scroll to.");
          } else {
            element.scrollIntoView();
            element.className = "mt-4 complete-button-selected";
          }
        }), 50);
}

function handleTablink(send, _event) {
  Curry._1(send, {
        TAG: /* Select */0,
        _0: /* Learn */0
      });
  scrollCompleteButtonIntoViewEventually(undefined);
}

function tabButton(tab, state, send, targetStatus) {
  return React.createElement("button", {
              key: "select-" + tabToString(targetStatus, tab),
              "aria-selected": Caml_obj.equal(tab, state.tab),
              className: tabClasses(tab, state.tab),
              role: "tab",
              onClick: (function (_e) {
                  Curry._1(send, {
                        TAG: /* Select */0,
                        _0: tab
                      });
                })
            }, tabToString(targetStatus, tab));
}

function tabLink(tab, state, send, targetStatus) {
  return React.createElement("button", {
              className: tabClasses(tab, state.tab),
              onClick: (function (param) {
                  Curry._1(send, {
                        TAG: /* Select */0,
                        _0: /* Learn */0
                      });
                  return scrollCompleteButtonIntoViewEventually(undefined);
                })
            }, tabToString(targetStatus, tab));
}

function tabOptions(state, send, targetDetails, targetStatus) {
  var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails);
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var tmp;
  if (typeof match === "number") {
    tmp = completionType >= 3 ? null : tabButton(/* Complete */{
            _0: completionType
          }, state, send, targetStatus);
  } else {
    var match$1 = match._0;
    tmp = typeof match$1 === "number" && !(match$1 >= 2 || completionType >= 3 || !Caml_obj.notequal(CoursesCurriculum__TargetDetails.submissions(targetDetails), [])) ? tabButton(/* Complete */{
            _0: completionType
          }, state, send, targetStatus) : null;
  }
  return React.createElement("div", {
              className: "flex justify-between max-w-3xl mx-auto -mb-px mt-5 md:mt-7",
              role: "tablist"
            }, Js_array.map((function (selection) {
                    return tabButton(selection, state, send, targetStatus);
                  }), selectableTabs(targetDetails)), tmp);
}

function addSubmission(target, state, send, addSubmissionCB, submission) {
  var targetDetails = state.targetDetails;
  if (targetDetails !== undefined) {
    var newTargetDetails = CoursesCurriculum__TargetDetails.addSubmission(submission, targetDetails);
    Curry._1(send, {
          TAG: /* SetTargetDetails */1,
          _0: newTargetDetails
        });
  }
  var match = CoursesCurriculum__Submission.status(submission);
  switch (match) {
    case /* MarkedAsComplete */0 :
        return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(false, CoursesCurriculum__Target.id(target)));
    case /* Pending */1 :
        return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(true, CoursesCurriculum__Target.id(target)));
    case /* Completed */2 :
        throw {
              RE_EXN_ID: UnexpectedSubmissionStatus,
              _1: "CoursesCurriculum__Overlay.addSubmission cannot handle a submsision with status Completed",
              Error: new Error()
            };
    case /* Rejected */3 :
        throw {
              RE_EXN_ID: UnexpectedSubmissionStatus,
              _1: "CoursesCurriculum__Overlay.addSubmission cannot handle a submsision with status Rejected",
              Error: new Error()
            };
    
  }
}

function addVerifiedSubmission(target, state, send, addSubmissionCB, submission) {
  var targetDetails = state.targetDetails;
  if (targetDetails !== undefined) {
    var newTargetDetails = CoursesCurriculum__TargetDetails.addSubmission(submission, targetDetails);
    Curry._1(send, {
          TAG: /* SetTargetDetails */1,
          _0: newTargetDetails
        });
  }
  return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(false, CoursesCurriculum__Target.id(target)));
}

function targetStatusClass(prefix, targetStatus) {
  return prefix + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
}

function renderTargetStatus(targetStatus) {
  var className = "curriculum__target-status bg-white text-xs mt-2 md:mt-0 py-1 px-2 md:px-4 curriculum__target-status--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
  return ReactUtils.nullIf(React.createElement("div", {
                  className: className
                }, CoursesCurriculum__TargetStatus.statusToString(targetStatus)), CoursesCurriculum__TargetStatus.isPending(targetStatus));
}

function overlayHeaderTitleCardClasses(targetStatus) {
  return "course-overlay__header-title-card relative flex justify-between items-center px-3 py-3 md:p-6 course-overlay__header-title-card--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
}

function renderLocked(text) {
  return React.createElement("div", {
              className: "mx-auto text-center bg-gray-900 text-white max-w-fc px-4 py-2 text-sm font-semibold relative z-10 rounded-b-lg"
            }, React.createElement("i", {
                  className: "fas fa-lock text-lg"
                }), React.createElement("span", {
                  className: "ms-2"
                }, text));
}

function overlayStatus(course, target, targetStatus, preview) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: overlayHeaderTitleCardClasses(targetStatus)
                }, React.createElement("button", {
                      "aria-label": t(undefined, undefined, "close_button"),
                      className: "course-overlay__close xl:absolute flex flex-col items-center justify-center absolute rounded-t-lg lg:rounded-t-none lg:rounded-b-lg leading-tight px-4 py-1 h-8 lg:h-full cursor-pointer border border-b-0 lg:border-transparent lg:border-t-0 lg:shadow hover:text-gray-900 hover:shadow-md focus:border-gray-300 focus:outline-none focus:text-gray-900 course-overlay__close--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus),
                      onClick: (function (_e) {
                          closeOverlay(course);
                        })
                    }, React.createElement(Icon.make, {
                          className: "if i-times-regular text-xl lg:text-2xl mt-1 lg:mt-0"
                        }), React.createElement("span", {
                          className: "text-xs hidden lg:inline-block mt-px"
                        }, t(undefined, undefined, "close_button"))), React.createElement("div", {
                      className: "w-full flex flex-wrap md:flex-nowrap items-center justify-between relative"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row items-start md:items-center font-medium leading-snug"
                        }, CoursesCurriculum__Target.milestone(target) ? React.createElement("div", {
                                className: "flex items-center flex-shrink-0 text-xs font-medium bg-yellow-100 text-yellow-800 border border-yellow-300 px-1.5 md:px-2 py-1 rounded-md mr-2"
                              }, React.createElement(Icon.make, {
                                    className: "if i-milestone-solid text-sm"
                                  }), React.createElement("span", {
                                    className: "ms-1"
                                  }, t(undefined, undefined, "milestone_target_label"))) : null, React.createElement("h1", {
                              className: "text-base leading-snug md:me-6 md:text-xl"
                            }, CoursesCurriculum__Target.title(target))), renderTargetStatus(targetStatus))), ReactUtils.nullUnless(React.createElement("div", undefined, renderLocked(t(undefined, undefined, "preview_mode_text"))), preview));
}

function renderLockReason(reason) {
  return renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason));
}

function prerequisitesIncomplete(reason, target, targets, statusOfTargets, send) {
  var prerequisiteTargetIds = CoursesCurriculum__Target.prerequisiteTargetIds(target);
  var prerequisiteTargets = Js_array.filter((function (target) {
          return Js_array.includes(CoursesCurriculum__Target.id(target), prerequisiteTargetIds);
        }), targets);
  return React.createElement("div", {
              className: "relative px-3 md:px-0"
            }, renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason)), React.createElement("div", {
                  className: "course-overlay__prerequisite-targets z-10 max-w-3xl mx-auto bg-white text-center rounded-lg overflow-hidden shadow mt-6"
                }, Js_array.map((function (target) {
                        var targetStatus = ArrayUtils.unsafeFind((function (ts) {
                                return CoursesCurriculum__TargetStatus.targetId(ts) === CoursesCurriculum__Target.id(target);
                              }), "Could not find status of target with ID " + CoursesCurriculum__Target.id(target), statusOfTargets);
                        return React.createElement(Link.make, {
                                    href: "/targets/" + CoursesCurriculum__Target.id(target),
                                    ariaLabel: "Select Target " + CoursesCurriculum__Target.id(target),
                                    className: "bg-white border-t px-6 py-4 relative z-10 flex items-center justify-between hover:bg-gray-50 hover:text-primary-500 cursor-pointer",
                                    onClick: (function (param) {
                                        Curry._1(send, /* ResetState */0);
                                      }),
                                    children: null,
                                    key: CoursesCurriculum__Target.id(target)
                                  }, React.createElement("span", {
                                        className: "font-semibold  leading-snug"
                                      }, CoursesCurriculum__Target.title(target)), renderTargetStatus(targetStatus));
                      }), prerequisiteTargets)));
}

function handleLocked(target, targets, targetStatus, statusOfTargets, send) {
  var reason = CoursesCurriculum__TargetStatus.status(targetStatus);
  if (typeof reason === "number") {
    return null;
  }
  var reason$1 = reason._0;
  if (typeof reason$1 === "number" && reason$1 >= 2) {
    return prerequisitesIncomplete(reason$1, target, targets, statusOfTargets, send);
  } else {
    return renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason$1));
  }
}

function overlayContentClasses(bool) {
  if (bool) {
    return "";
  } else {
    return "hidden";
  }
}

function addPageRead(targetId, markReadCB) {
  Js_promise.then_((function (response) {
          if (response.ok) {
            Curry._1(markReadCB, targetId);
            return Promise.resolve(undefined);
          } else {
            return Promise.reject({
                        RE_EXN_ID: UnexpectedResponse,
                        _1: response.status
                      });
          }
        }), fetch("/targets/" + (targetId + "/mark_as_read"), Fetch.RequestInit.make(/* Post */2, [
                  [
                    "X-CSRF-Token",
                    AuthenticityToken.fromHead(undefined)
                  ],
                  [
                    "Content-Type",
                    "application/json"
                  ]
                ], undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)));
}

function learnSection(send, targetDetails, targetRead, tab, author, courseId, targetId, markReadCB, targetStatus, completionType, preview) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var suffixLinkInfo;
  if (typeof match === "number" && !(match === 2 || match === 1)) {
    switch (completionType) {
      case /* Evaluated */0 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_submit_work"),
            "fas fa-feather-alt"
          ];
          break;
      case /* TakeQuiz */1 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_take_quiz"),
            "fas fa-tasks"
          ];
          break;
      case /* SubmitForm */2 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_submit_form"),
            "fas fa-feather-alt"
          ];
          break;
      case /* NoAssignment */3 :
          suffixLinkInfo = undefined;
          break;
      
    }
  } else {
    suffixLinkInfo = undefined;
  }
  var linkToTab = Belt_Option.mapWithDefault(suffixLinkInfo, null, (function (param) {
          var tab = param[0];
          return React.createElement("button", {
                      className: "cursor-pointer flex rounded btn-success text-base justify-center w-full font-semibold p-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 curriculum-overlay__learn-submit-btn",
                      onClick: (function (param) {
                          Curry._1(send, {
                                TAG: /* Select */0,
                                _0: tab
                              });
                        })
                    }, React.createElement("span", undefined, React.createElement(FaIcon.make, {
                              classes: param[2] + " me-2"
                            }), param[1]));
        }));
  return React.createElement("div", {
              className: tab === /* Learn */0 ? "" : "hidden"
            }, React.createElement(CoursesCurriculum__Learn.make, {
                  targetDetails: targetDetails,
                  author: author,
                  courseId: courseId,
                  targetId: targetId
                }), React.createElement("div", {
                  className: "flex flex-wrap gap-4 mt-4"
                }, targetRead ? React.createElement("div", {
                        className: "flex rounded text-base italic space-x-2 bg-gray-50 text-gray-600 items-center justify-center w-full font-semibold p-3"
                      }, React.createElement("span", {
                            className: "w-5 h-5 flex items-center justify-center",
                            title: "Marked read"
                          }, React.createElement("svg", {
                                className: "w-5 h-5 text-gray-500",
                                height: "16",
                                width: "16",
                                fill: "currentColor",
                                viewBox: "0 0 16 16",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, React.createElement("path", {
                                    d: "M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"
                                  }), React.createElement("path", {
                                    d: "m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"
                                  }))), React.createElement("span", undefined, t(undefined, undefined, "marked_read"))) : React.createElement("button", {
                        className: "btn btn-default flex space-x-2 text-base w-full p-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 curriculum-overlay__learn-submit-btn",
                        disabled: preview || !CoursesCurriculum__TargetStatus.readable(targetStatus),
                        onClick: (function (param) {
                            addPageRead(targetId, markReadCB);
                          })
                      }, React.createElement("span", {
                            className: "w-2 h-2 inline-block rounded-full bg-blue-600"
                          }), React.createElement("span", undefined, t(undefined, undefined, "mark_as_read"))), linkToTab));
}

function discussSection(target, targetDetails, tab) {
  return React.createElement("div", {
              className: tab === /* Discuss */1 ? "" : "hidden"
            }, React.createElement(CoursesCurriculum__Discuss.make, {
                  targetId: CoursesCurriculum__Target.id(target),
                  communities: CoursesCurriculum__TargetDetails.communities(targetDetails)
                }));
}

function completeSectionClasses(tab, completionType) {
  if (typeof tab === "number" && (tab !== 0 || completionType < 3)) {
    return "hidden";
  } else {
    return "";
  }
}

function completeSection(state, send, target, targetDetails, targetStatus, addSubmissionCB, evaluationCriteria, coaches, users, preview, completionType, currentUser) {
  var addVerifiedSubmissionCB = function (param) {
    return addVerifiedSubmission(target, state, send, addSubmissionCB, param);
  };
  var targetId = CoursesCurriculum__Target.id(target);
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (typeof match === "number") {
    if (match !== 0) {
      exit$1 = 2;
    } else {
      switch (completionType) {
        case /* Evaluated */0 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Instructions",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__SubmissionBuilder.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: (function (param) {
                        return addSubmission(target, state, send, addSubmissionCB, param);
                      }),
                    preview: preview,
                    checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails),
                    key: "courses-curriculum-submission-form"
                  })
            ];
            break;
        case /* TakeQuiz */1 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Instructions",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__Quiz.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: addVerifiedSubmissionCB,
                    preview: preview,
                    key: "courses-curriculum-quiz"
                  })
            ];
            break;
        case /* SubmitForm */2 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Instructions",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__SubmissionBuilder.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: (function (param) {
                        return addSubmission(target, state, send, addSubmissionCB, param);
                      }),
                    preview: preview,
                    checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails),
                    key: "courses-curriculum-submission-form"
                  })
            ];
            break;
        case /* NoAssignment */3 :
            exit = 1;
            break;
        
      }
    }
  } else {
    var match$1 = match._0;
    if (typeof match$1 === "number" && match$1 < 2) {
      exit$1 = 2;
    } else {
      exit = 1;
    }
  }
  if (exit$1 === 2) {
    if (completionType >= 3) {
      exit = 1;
    } else {
      tmp = React.createElement(CoursesCurriculum__SubmissionsAndFeedback.make, {
            currentUser: currentUser,
            targetDetails: targetDetails,
            target: target,
            evaluationCriteria: evaluationCriteria,
            addSubmissionCB: (function (param) {
                return addSubmission(target, state, send, addSubmissionCB, param);
              }),
            targetStatus: targetStatus,
            coaches: coaches,
            users: users,
            preview: preview,
            checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails)
          });
    }
  }
  if (exit === 1) {
    tmp = null;
  }
  var tmp$1;
  if (CoursesCurriculum__TargetDetails.discussion(targetDetails) && CoursesCurriculum__CurrentUser.isParticipant(currentUser)) {
    var submissions = state.peerSubmissions;
    var tmp$2;
    if (typeof submissions === "number") {
      tmp$2 = React.createElement("div", undefined, SkeletonLoading.multiple(6, SkeletonLoading.card(undefined, undefined)));
    } else if (submissions.TAG === /* PartiallyLoaded */0) {
      var cursor = submissions._1;
      var times = state.loading;
      tmp$2 = React.createElement("div", undefined, submissionsList(submissions._0, state, currentUser, (function (param) {
                  return getDiscussionSubmissions(send, undefined, param);
                })), times ? ReactUtils.nullUnless(React.createElement("div", {
                      className: "pb-6"
                    }, React.createElement("button", {
                          className: "btn btn-primary-ghost cursor-pointer w-full",
                          onClick: (function (param) {
                              Curry._1(send, /* BeginLoadingMore */3);
                              getDiscussionSubmissions(send, cursor, targetId);
                            })
                        }, t(undefined, undefined, "button_load_more"))), ArrayUtils.isEmpty(times._0)) : React.createElement("div", undefined, SkeletonLoading.multiple(1, SkeletonLoading.card(undefined, undefined))));
    } else {
      tmp$2 = React.createElement("div", undefined, submissionsList(submissions._0, state, currentUser, (function (param) {
                  return getDiscussionSubmissions(send, undefined, param);
                })));
    }
    var match$2 = state.peerSubmissions;
    var tmp$3;
    if (typeof match$2 === "number") {
      tmp$3 = null;
    } else {
      var times$1 = state.loading;
      var loading = times$1 ? ArrayUtils.isNotEmpty(times$1._0) : false;
      tmp$3 = React.createElement(LoadingSpinner.make, {
            loading: loading
          });
    }
    tmp$1 = React.createElement("div", {
          className: "border-t mt-12"
        }, React.createElement("div", {
              className: "max-w-3xl mx-auto"
            }, React.createElement("h4", {
                  className: "text-base md:text-lg font-semibold pt-12 pb-4"
                }, t(undefined, undefined, "submissions_peers")), React.createElement("div", undefined, tmp$2), tmp$3));
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: completeSectionClasses(state.tab, completionType)
                }, CoursesCurriculum__TargetDetails.discussion(targetDetails) ? React.createElement("div", {
                        className: "bg-primary-100 max-w-3xl mx-auto rounded-lg px-4 md:px-6 py-4 flex flex-col-reverse sm:flex-row items-start md:items-center justify-between"
                      }, React.createElement("div", {
                            className: "sm:me-12 mt-2 sm:mt-0"
                          }, React.createElement("h3", {
                                className: "leading-tight font-semibold"
                              }, t(undefined, undefined, "discussion_assignment_notice.title")), React.createElement("p", {
                                className: "text-sm text-gray-600 pt-1"
                              }, t(undefined, undefined, "discussion_assignment_notice.description"))), React.createElement("div", {
                            className: "shrink-0 w-16 sm:w-32 me-4 sm:me-0"
                          }, React.createElement("img", {
                                className: "object-contain mx-auto",
                                src: assignmentDiscussionIcon
                              }))) : null, React.createElement("div", {
                      className: "max-w-3xl mx-auto"
                    }, tmp), tmp$1));
}

function renderPendingStudents(pendingUserIds, users) {
  return React.createElement("div", {
              className: "max-w-3xl mx-auto text-center mt-4"
            }, React.createElement("div", {
                  className: "font-semibold text-md"
                }, t(undefined, undefined, "pending_team_members_notice")), React.createElement("div", {
                  className: "flex justify-center flex-wrap"
                }, Js_array.map((function (studentId) {
                        var user = ArrayUtils.unsafeFind((function (u) {
                                return CoursesCurriculum__User.id(u) === studentId;
                              }), "Unable to find user with id " + (studentId + "in CoursesCurriculum__Overlay"), users);
                        return React.createElement("div", {
                                    key: CoursesCurriculum__User.id(user),
                                    className: "w-10 h-10 rounded-full border border-yellow-400 flex items-center justify-center overflow-hidden mx-1 shadow-md shrink-0 mt-2",
                                    title: CoursesCurriculum__User.name(user) + " has not completed this target."
                                  }, CoursesCurriculum__User.avatar(user));
                      }), pendingUserIds)));
}

function handlePendingStudents(targetStatus, targetDetails, users) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  if (targetDetails === undefined) {
    return null;
  }
  if (typeof match !== "number") {
    return null;
  }
  if (!(match === 2 || match === 1)) {
    return null;
  }
  var pendingUserIds = CoursesCurriculum__TargetDetails.pendingUserIds(targetDetails);
  if (Caml_obj.equal(pendingUserIds, [])) {
    return null;
  } else {
    return renderPendingStudents(pendingUserIds, users);
  }
}

function performQuickNavigation(send, _event) {
  var element = document.getElementById("target-overlay");
  if (!(element == null)) {
    element.scrollTop = 0.0;
  }
  return Curry._1(send, /* PerformQuickNavigation */1);
}

function navigationLink(direction, url, send) {
  var match = direction === "Next" ? [
      undefined,
      t(undefined, undefined, "next_target_button"),
      "fa-arrow-right"
    ] : [
      "fa-arrow-left",
      t(undefined, undefined, "previous_target_button"),
      undefined
    ];
  var arrow = function (icon) {
    return Belt_Option.mapWithDefault(icon, null, (function (icon) {
                  return React.createElement(FaIcon.make, {
                              classes: "rtl:rotate-180 fas " + icon
                            });
                }));
  };
  return React.createElement(Link.make, {
              href: url,
              className: "block p-2 md:p-4 text-center border rounded-lg bg-gray-50 hover:bg-gray-50",
              onClick: (function (param) {
                  return performQuickNavigation(send, param);
                }),
              children: null
            }, arrow(match[0]), React.createElement("span", {
                  className: "mx-2 hidden md:inline"
                }, match[1]), arrow(match[2]));
}

function scrollOverlayToTop(_event) {
  var element = document.getElementById("target-overlay");
  Belt_Option.mapWithDefault((element == null) ? undefined : Caml_option.some(element), undefined, (function (element) {
          element.scrollTop = 0.0;
        }));
}

function quickNavigationLinks(targetDetails, send) {
  var match = CoursesCurriculum__TargetDetails.navigation(targetDetails);
  return React.createElement("div", {
              className: "pb-6"
            }, React.createElement("hr", {
                  className: "my-6"
                }), React.createElement("div", {
                  className: "mx-auto max-w-3xl flex px-3 lg:px-0",
                  id: "target-navigation"
                }, React.createElement("div", {
                      className: "w-1/3 me-2"
                    }, Belt_Option.mapWithDefault(match[0], null, (function (previousUrl) {
                            return navigationLink("Previous", previousUrl, send);
                          }))), React.createElement("div", {
                      className: "w-1/3 mx-2"
                    }, React.createElement("button", {
                          className: "block w-full focus:outline-none p-2 md:p-4 text-center border rounded-lg bg-gray-50 hover:bg-gray-50",
                          onClick: scrollOverlayToTop
                        }, React.createElement("span", {
                              className: "mx-2 hidden md:inline"
                            }, t(undefined, undefined, "scroll_to_top")), React.createElement("span", {
                              className: "mx-2 md:hidden"
                            }, React.createElement("i", {
                                  className: "fas fa-arrow-up"
                                })))), React.createElement("div", {
                      className: "w-1/3 ms-2"
                    }, Belt_Option.mapWithDefault(match[1], null, (function (nextUrl) {
                            return navigationLink("Next", nextUrl, send);
                          })))));
}

function updatePendingUserIdsWhenAddingSubmission(send, target, addSubmissionCB, submission) {
  Curry._1(send, {
        TAG: /* AddSubmission */2,
        _0: CoursesCurriculum__Target.role(target)
      });
  return Curry._1(addSubmissionCB, submission);
}

function CoursesCurriculum__Overlay(Props) {
  var target = Props.target;
  var course = Props.course;
  var targetStatus = Props.targetStatus;
  var addSubmissionCB = Props.addSubmissionCB;
  var targets = Props.targets;
  var targetRead = Props.targetRead;
  var markReadCB = Props.markReadCB;
  var statusOfTargets = Props.statusOfTargets;
  var users = Props.users;
  var evaluationCriteria = Props.evaluationCriteria;
  var coaches = Props.coaches;
  var preview = Props.preview;
  var author = Props.author;
  var currentUser = Props.currentUser;
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          return loadTargetDetails(target, currentUser, send, undefined);
        }), [CoursesCurriculum__Target.id(target)]);
  React.useEffect(function () {
        ScrollLock.activate(undefined);
        return (function (param) {
                  ScrollLock.deactivate(undefined);
                });
      });
  var targetDetails = state.targetDetails;
  var targetDetails$1 = state.targetDetails;
  var tmp;
  if (targetDetails$1 !== undefined) {
    var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails$1);
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "mx-auto mt-6 md:mt-8 px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "max-w-3xl mx-auto"
                }, learnSection(send, targetDetails$1, targetRead, state.tab, author, CoursesCurriculum__Course.id(course), CoursesCurriculum__Target.id(target), markReadCB, targetStatus, completionType, preview)), React.createElement("div", {
                  className: "max-w-3xl mx-auto"
                }, discussSection(target, targetDetails$1, state.tab)), completeSection(state, send, target, targetDetails$1, targetStatus, (function (param) {
                    return updatePendingUserIdsWhenAddingSubmission(send, target, addSubmissionCB, param);
                  }), evaluationCriteria, coaches, users, preview, completionType, currentUser)), quickNavigationLinks(targetDetails$1, send));
  } else {
    tmp = React.createElement("div", {
          className: "course-overlay__skeleton-body-container max-w-3xl w-full pb-4 mx-auto"
        }, React.createElement("div", {
              className: "course-overlay__skeleton-body-wrapper mt-8 px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-md mt-4 w-1/2 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-image-placeholder mt-5 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-2/5 skeleton-animate"
                })), React.createElement("div", {
              className: "course-overlay__skeleton-body-wrapper mt-8 px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                })));
  }
  return React.createElement("div", {
              className: "fixed z-50 top-0 start-0 w-full h-full overflow-y-scroll bg-white",
              id: "target-overlay"
            }, React.createElement("div", {
                  className: "bg-gray-50 border-b border-gray-300 px-3"
                }, React.createElement("div", {
                      className: "course-overlay__header-container pt-12 lg:pt-0 mx-auto"
                    }, overlayStatus(course, target, targetStatus, preview), handleLocked(target, targets, targetStatus, statusOfTargets, send), handlePendingStudents(targetStatus, state.targetDetails, users), targetDetails !== undefined ? tabOptions(state, send, targetDetails, targetStatus) : React.createElement("div", {
                            className: "course-overlay__skeleton-head-container max-w-3xl w-full mx-auto"
                          }, React.createElement("div", {
                                className: "course-overlay__skeleton-head-wrapper bg-white h-13 flex items-center justify-between border border-b-0 rounded-t-lg mt-5 md:mt-7"
                              }, React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }), React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }), React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }))))), tmp);
}

var TargetStatus;

var make$1 = CoursesCurriculum__Overlay;

export {
  UnexpectedSubmissionStatus ,
  UnexpectedResponse ,
  TargetStatus ,
  noPeerSubmissionIcon ,
  assignmentDiscussionIcon ,
  str ,
  t ,
  Item ,
  PagedSubmission ,
  initialState ,
  reducer ,
  closeOverlay ,
  DiscussionSubmissionsQuery ,
  getDiscussionSubmissions ,
  reloadSubmissions ,
  loadTargetDetails ,
  submissionsLoadedData ,
  submissionsList ,
  completionTypeToString ,
  tabToString ,
  selectableTabs ,
  tabClasses ,
  scrollCompleteButtonIntoViewEventually ,
  handleTablink ,
  tabButton ,
  tabLink ,
  tabOptions ,
  addSubmission ,
  addVerifiedSubmission ,
  targetStatusClass ,
  renderTargetStatus ,
  overlayHeaderTitleCardClasses ,
  renderLocked ,
  overlayStatus ,
  renderLockReason ,
  prerequisitesIncomplete ,
  handleLocked ,
  overlayContentClasses ,
  addPageRead ,
  learnSection ,
  discussSection ,
  completeSectionClasses ,
  completeSection ,
  renderPendingStudents ,
  handlePendingStudents ,
  performQuickNavigation ,
  navigationLink ,
  scrollOverlayToTop ,
  quickNavigationLinks ,
  updatePendingUserIdsWhenAddingSubmission ,
  make$1 as make,
}
/*  Not a pure module */

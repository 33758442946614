// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Belt_Option from "../../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          levelId: Json_decode.field("levelId", Json_decode.string, json),
          endsAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("endsAt", Json_decode.string, param);
                    }), json), DateFns.parseISO),
          completedAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("completedAt", Json_decode.string, param);
                    }), json), DateFns.parseISO)
        };
}

function levelId(t) {
  return t.levelId;
}

function endsAt(t) {
  return t.endsAt;
}

function completedAt(t) {
  return t.completedAt;
}

function accessEnded(t) {
  return Belt_Option.mapWithDefault(t.endsAt, false, DateFns.isPast);
}

export {
  decode ,
  levelId ,
  endsAt ,
  completedAt ,
  accessEnded ,
}
/* DateFns Not a pure module */

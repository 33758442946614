// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";

function make(id, title, createdAt, status, targetId, targetRole, milestoneNumber) {
  return {
          id: id,
          title: title,
          createdAt: createdAt,
          status: status,
          targetId: targetId,
          targetRole: targetRole,
          milestoneNumber: milestoneNumber
        };
}

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function status(t) {
  return t.status;
}

function createdAt(t) {
  return t.createdAt;
}

function targetId(t) {
  return t.targetId;
}

function targetRole(t) {
  return t.targetRole;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function milestoneNumber(t) {
  return t.milestoneNumber;
}

function makeFromJs(submissions) {
  return Js_array.map((function (submission) {
                var createdAt = DateFns.decodeISO(submission.createdAt);
                var _passedAt = submission.passedAt;
                var status;
                if (_passedAt !== undefined) {
                  status = "Completed";
                } else {
                  var _time = submission.evaluatedAt;
                  status = _time !== undefined ? "Rejected" : "PendingReview";
                }
                var targetRole = submission.teamTarget ? /* Team */({
                      _0: submission.studentIds
                    }) : /* Student */0;
                return make(submission.id, submission.title, createdAt, status, submission.targetId, targetRole, submission.milestoneNumber);
              }), submissions);
}

export {
  make ,
  id ,
  title ,
  status ,
  createdAt ,
  targetId ,
  targetRole ,
  createdAtPretty ,
  milestoneNumber ,
  makeFromJs ,
}
/* DateFns Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Tooltip from "../../shared/components/Tooltip.bs.js";
import * as Js_promise from "../../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as TopicsShow__Post from "../types/TopicsShow__Post.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.TopicsShow__LikeManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreatePostLikeMutation($postId: ID!)  {\ncreatePostLike(postId: $postId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createPostLike;
  return {
          createPostLike: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.createPostLike;
  var value$2 = value$1.success;
  var createPostLike = {
    success: value$2
  };
  return {
          createPostLike: createPostLike
        };
}

function serializeVariables(inp) {
  return {
          postId: inp.postId
        };
}

function makeVariables(postId, param) {
  return {
          postId: postId
        };
}

var CreatePostLikeQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var CreatePostLikeQuery_Graphql_error = include.Graphql_error;

var CreatePostLikeQuery_decodeNotification = include.decodeNotification;

var CreatePostLikeQuery_decodeObject = include.decodeObject;

var CreatePostLikeQuery_decodeNotifications = include.decodeNotifications;

var CreatePostLikeQuery_decodeErrors = include.decodeErrors;

var CreatePostLikeQuery_flashNotifications = include.flashNotifications;

var CreatePostLikeQuery_sendQuery = include.sendQuery;

var CreatePostLikeQuery_query = include.query;

var CreatePostLikeQuery_make = include.make;

var CreatePostLikeQuery = {
  CreatePostLikeQuery_inner: CreatePostLikeQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreatePostLikeQuery_Graphql_error,
  decodeNotification: CreatePostLikeQuery_decodeNotification,
  decodeObject: CreatePostLikeQuery_decodeObject,
  decodeNotifications: CreatePostLikeQuery_decodeNotifications,
  decodeErrors: CreatePostLikeQuery_decodeErrors,
  flashNotifications: CreatePostLikeQuery_flashNotifications,
  sendQuery: CreatePostLikeQuery_sendQuery,
  query: CreatePostLikeQuery_query,
  $$fetch: $$fetch,
  make: CreatePostLikeQuery_make
};

var Raw$1 = {};

var query$1 = "mutation DeletePostLikeMutation($postId: ID!)  {\ndeletePostLike(postId: $postId)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.deletePostLike;
  return {
          deletePostLike: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.deletePostLike;
  var value$2 = value$1.success;
  var deletePostLike = {
    success: value$2
  };
  return {
          deletePostLike: deletePostLike
        };
}

function serializeVariables$1(inp) {
  return {
          postId: inp.postId
        };
}

function makeVariables$1(postId, param) {
  return {
          postId: postId
        };
}

var DeletePostLikeQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var DeletePostLikeQuery_Graphql_error = include$1.Graphql_error;

var DeletePostLikeQuery_decodeNotification = include$1.decodeNotification;

var DeletePostLikeQuery_decodeObject = include$1.decodeObject;

var DeletePostLikeQuery_decodeNotifications = include$1.decodeNotifications;

var DeletePostLikeQuery_decodeErrors = include$1.decodeErrors;

var DeletePostLikeQuery_flashNotifications = include$1.flashNotifications;

var DeletePostLikeQuery_sendQuery = include$1.sendQuery;

var DeletePostLikeQuery_query = include$1.query;

var DeletePostLikeQuery_make = include$1.make;

var DeletePostLikeQuery = {
  DeletePostLikeQuery_inner: DeletePostLikeQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: DeletePostLikeQuery_Graphql_error,
  decodeNotification: DeletePostLikeQuery_decodeNotification,
  decodeObject: DeletePostLikeQuery_decodeObject,
  decodeNotifications: DeletePostLikeQuery_decodeNotifications,
  decodeErrors: DeletePostLikeQuery_decodeErrors,
  flashNotifications: DeletePostLikeQuery_flashNotifications,
  sendQuery: DeletePostLikeQuery_sendQuery,
  query: DeletePostLikeQuery_query,
  $$fetch: $$fetch$1,
  make: DeletePostLikeQuery_make
};

function iconClasses(liked, saving) {
  return "text-lg" + (
          saving ? " fas fa-thumbs-up cursor-pointer text-primary-200" : (
              liked ? " fas fa-thumbs-up cursor-pointer text-primary-400" : " far fa-thumbs-up cursor-pointer"
            )
        );
}

function handlePostLike(saving, liked, setSaving, postId, removeLikeCB, handleCreateResponse, addLikeCB, $$event) {
  $$event.preventDefault();
  if (saving) {
    return ;
  } else {
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    if (liked) {
      Js_promise.$$catch((function (param) {
              Curry._1(setSaving, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), Js_promise.then_((function (response) {
                  if (response.deletePostLike.success) {
                    Curry._1(removeLikeCB, undefined);
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  } else {
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  }
                  return Promise.resolve(undefined);
                }), Curry._3($$fetch$1, undefined, undefined, {
                    postId: postId
                  })));
    } else {
      Js_promise.$$catch((function (param) {
              Curry._1(setSaving, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), Js_promise.then_((function (response) {
                  if (response.createPostLike.success) {
                    Curry._2(handleCreateResponse, setSaving, addLikeCB);
                  } else {
                    Curry._1(setSaving, (function (param) {
                            return false;
                          }));
                  }
                  return Promise.resolve(undefined);
                }), Curry._3($$fetch, undefined, undefined, {
                    postId: postId
                  })));
    }
    return ;
  }
}

function handleCreateResponse(setSaving, addLikeCB) {
  Curry._1(setSaving, (function (param) {
          return false;
        }));
  return Curry._1(addLikeCB, undefined);
}

function TopicsShow__LikeManager(Props) {
  var post = Props.post;
  var addPostLikeCB = Props.addPostLikeCB;
  var removePostLikeCB = Props.removePostLikeCB;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var saving = match[0];
  var liked = TopicsShow__Post.likedByUser(post);
  var tip = React.createElement("div", {
        className: "text-center"
      }, t(undefined, undefined, "like_button_tooltip"));
  var partial_arg = TopicsShow__Post.id(post);
  return React.createElement("div", {
              className: "flex flex-row-reverse md:flex-row"
            }, React.createElement("div", {
                  className: "text-center pe-4 md:pt-4"
                }, React.createElement(Tooltip.make, {
                      tip: tip,
                      position: "Top",
                      children: React.createElement("button", {
                            "aria-label": (
                              liked ? t(undefined, undefined, "unlike") : t(undefined, undefined, "like")
                            ) + (" " + t(undefined, undefined, "post") + " " + TopicsShow__Post.id(post)),
                            className: "cursor-pointer flex md:flex-col items-center",
                            onClick: (function (param) {
                                return handlePostLike(saving, liked, setSaving, partial_arg, removePostLikeCB, handleCreateResponse, addPostLikeCB, param);
                              })
                          }, React.createElement("div", {
                                key: iconClasses(liked, saving),
                                className: "flex items-center justify-center rounded-lg lg:rounded-full lg:bg-gray-50 hover:bg-gray-300 text-gray-600 hover:text-gray-900 h-8 w-8 md:h-10 md:w-10 p-1 md:p-2 mx-auto"
                              }, React.createElement("i", {
                                    className: iconClasses(liked, saving)
                                  })), React.createElement("p", {
                                className: "text-tiny lg:text-xs font-semibold"
                              }, String(TopicsShow__Post.totalLikes(post))))
                    })));
}

var make = TopicsShow__LikeManager;

export {
  str ,
  t ,
  CreatePostLikeQuery ,
  DeletePostLikeQuery ,
  iconClasses ,
  handlePostLike ,
  handleCreateResponse ,
  make ,
}
/* include Not a pure module */

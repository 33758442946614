// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../../../../shared/node_modules/rescript/lib/es6/caml_obj.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decodeAnswerOption(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.string, json)
        };
}

function decode(json) {
  return {
          index: Json_decode.field("index", Json_decode.$$int, json),
          question: Json_decode.field("question", Json_decode.string, json),
          answerOptions: Json_decode.field("answerOptions", (function (param) {
                  return Json_decode.array(decodeAnswerOption, param);
                }), json)
        };
}

function index(t) {
  return t.index;
}

function question(t) {
  return t.question;
}

function answerOptions(t) {
  return t.answerOptions;
}

function answerId(answerOption) {
  return answerOption.id;
}

function answerValue(answerOption) {
  return answerOption.value;
}

function lastQuestion(questions) {
  var maxIndex = ArrayUtils.last(ArrayUtils.copyAndSort((function (q1, q2) {
              return q1.index - q2.index | 0;
            }), questions)).index;
  return ArrayUtils.unsafeFind((function (q) {
                return q.index === maxIndex;
              }), "Could not find last question at index " + String(maxIndex), questions);
}

function nextQuestion(questions, question) {
  return ArrayUtils.unsafeFind((function (q) {
                return q.index === (question.index + 1 | 0);
              }), "Could not find a question at index " + String(question.index + 1 | 0), questions);
}

function isLastQuestion(questions, question) {
  return Caml_obj.equal(lastQuestion(questions), question);
}

export {
  decodeAnswerOption ,
  decode ,
  index ,
  question ,
  answerOptions ,
  answerId ,
  answerValue ,
  lastQuestion ,
  nextQuestion ,
  isLastQuestion ,
}
/* ArrayUtils Not a pure module */

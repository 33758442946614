// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../../../shared/node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";

function decode(json) {
  return {
          submissionId: Json_decode.field("submissionId", Json_decode.string, json),
          evaluationCriterionId: Json_decode.field("evaluationCriterionId", Json_decode.string, json),
          grade: Json_decode.field("grade", Json_decode.$$int, json)
        };
}

function sort(criteria, grades) {
  return ArrayUtils.copyAndSort((function (g1, g2) {
                var ec1 = ArrayUtils.unsafeFind((function (ec) {
                        return EvaluationCriterion.id(ec) === g1.evaluationCriterionId;
                      }), "Unable to find evaluation criterion with ID: " + (g1.evaluationCriterionId + " in CoursesCurriculum__Grade"), criteria);
                var ec2 = ArrayUtils.unsafeFind((function (ec) {
                        return EvaluationCriterion.id(ec) === g2.evaluationCriterionId;
                      }), "Unable to find evaluation criterion with ID: " + (g2.evaluationCriterionId + " in CoursesCurriculum__Grade"), criteria);
                return $$String.compare(EvaluationCriterion.name(ec1), EvaluationCriterion.name(ec2));
              }), grades);
}

function grade(t) {
  return t.grade;
}

function submissionId(t) {
  return t.submissionId;
}

function evaluationCriterionId(t) {
  return t.evaluationCriterionId;
}

export {
  decode ,
  sort ,
  grade ,
  submissionId ,
  evaluationCriterionId ,
}
/* ArrayUtils Not a pure module */
